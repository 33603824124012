import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import BillableTable from './BillableTable/BillableTable';
import BillableCalender from './BillableCalender/BillableCalender';
import FinancialService from '../../../../AppSettings/services/FinancialService';
import './BillableTime.css';
import { useMsal } from "@azure/msal-react";
import EmployeeService from '../../../../AppSettings/services/EmployeeService';
import BillableTimeForm from './BillableTimeForm/BillableTimeForm'; // Import the new form component

const BillableTime = () => {
    const { accounts } = useMsal(); // Get logged-in user info
    const employeeid = accounts[0]?.idTokenClaims?.oid;
    const [employee, setEmployee] = useState([]);
    const { matterId } = useParams();
    const [billedTimeData, setBilledTimeData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isTableView, setIsTableView] = useState(true);
    const [showAddForm, setShowAddForm] = useState(false);

    // Fetch billed time data
    useEffect(() => {
        const fetchBilledTime = async () => {
            try {
                setIsLoading(true);
                const data = await FinancialService.getBilledTime(matterId);
                setBilledTimeData(data);
            } catch (error) {
                console.error("Error fetching billed time data:", error);
                setError("Failed to fetch billed time data.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchBilledTime();
        getEmployeeById(employeeid);
    }, [employeeid, matterId]);

    const getEmployeeById = async (employeeid) => {
        try {
            const employee = await EmployeeService.getEmployeeById(employeeid);
            setEmployee(employee);
        } catch (error) {
            console.error("Error fetching employee:", error);
            setError("Failed to load employee.");
        }
    };

    // Add a new billed time entry
    const handleAddBilledTime = async (formData) => {
        try {
            const response = await FinancialService.addBilledTime(formData);

            // Check for non-2xx responses (e.g., 400, 404, 500)
            if (!response) {
                throw new Error(`Request failed`);
            }

            setError("");
            const adjustedEntry = { ...formData, employee_name: `${employee.firstname} ${employee.lastname}`, time_recorded: formData.timeRecorded };
            setBilledTimeData((prevData) => [...prevData, adjustedEntry]);
        } catch (error) {
            console.error("Error adding billed time entry:", error);
            setError("Failed to add billed time entry.");
        }
    };

    if (!employee) {
        return <p>Loading employee data...</p>;
    }

    return (
        <div className="billable-container">
            <div className="billableTable-header">
                <h1>Billable Time</h1>
                <div className="billableTable-actions">
                    <button
                        className={`MF-toggle-view-button ${isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(true)}
                        aria-label="Table View"
                    >
                        <i className="fas fa-table"></i>
                    </button>
                    <button
                        className={`MF-toggle-view-button ${!isTableView ? 'active' : ''}`}
                        onClick={() => setIsTableView(false)}
                        aria-label="Calendar View"
                    >
                        <i className="fas fa-calendar-alt"></i>
                    </button>
                    <button
                        className="MF-billableTable-add-button"
                        onClick={() => setShowAddForm(true)}
                    >
                        +
                    </button>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                isTableView ? (
                    <BillableTable
                        data={billedTimeData}
                        matterId={matterId}
                        onAddBilledTime={handleAddBilledTime}
                    />
                ) : (
                    <BillableCalender
                        data={billedTimeData}
                        matterId={matterId}
                        onAddBilledTime={handleAddBilledTime}
                    />
                )
            )}

            {showAddForm && (
                <BillableTimeForm 
                    onSubmit={handleAddBilledTime}
                    employee={employee}
                    setShowAddForm={setShowAddForm}
                    matterId={matterId}
                />
            )}
        </div>
    );
};

export default BillableTime;
