import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react"; 
import "./TasksPage.css";
import TasksTopBar from "./Tasks/Components/TopBar/TopBar.js";
import TasksTable from "./Tasks/Components/TaskTable/TasksTable";
import DeadlinesTable from "./Deadline/Components/DeadlineTable/DeadlineTable";
import ChecklistTable from "./Checklist/Components/ChecklistTable/ChecklistTable";
import TasksPagination from "./Tasks/Components/Pagination/Pagination";
import DataBar from "./Tasks/Components/DataBar/DataBar";
import TaskService from "../AppSettings/services/TaskService";
import DeadlineChecklistService from "../AppSettings/services/DeadlineChecklistService";

const PAGELIMIT = 12;

// Define columns for each type of table
const taskColumns = {
  Task: "task_title",
  Status: "status",
  Type: "task_type",
  "Assigned To": "assignedto",
  "Due Date": "duedate"
};

const deadlineColumns = {
  Title: "deadline_type",
  "Deadline Type": "type",
  "Due Date": "due_date",
  "Status": "internal_external",
  "Matter ID": "matterid"
};

const checklistColumns = {
  Description: "description",
  "Matter ID": "matterid"
};

const TasksPage = () => {
  const { accounts } = useMsal();
  const [tasks, setTasks] = useState([]);
  const [deadlines, setDeadlines] = useState([]); 
  const [checklistItems, setChecklistItems] = useState([]); 
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filteredDeadlines, setFilteredDeadlines] = useState([]);
  const [filteredChecklistItems, setFilteredChecklistItems] = useState([]);
  const [page, setPage] = useState(1);
  const [showMyTasks, setShowMyTasks] = useState(true);
  const [activeTable, setActiveTable] = useState("Tasks");

  const employeeId = accounts[0]?.idTokenClaims?.oid;

  useEffect(() => {
    if (employeeId) {
      fetchTasks();
    }
    if (activeTable === "Deadlines") {
      fetchDeadlines();
    } else if (activeTable === "Checklist") {
      fetchChecklistItems(); 
    }
  }, [employeeId, showMyTasks, activeTable]);

  const fetchTasks = async () => {
    try {
      let data;
      if (showMyTasks && employeeId) {
        data = await TaskService.getTasksByEmployeeId(employeeId);
      } else {
        data = await TaskService.getAllTasks();
      }
      setTasks(data);
      setFilteredTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchDeadlines = async () => {
    try {
      const data = await DeadlineChecklistService.getAllDeadlines();
      setDeadlines(data);
      setFilteredDeadlines(data);
    } catch (error) {
      console.error("Error fetching deadlines:", error);
    }
  };

  const fetchChecklistItems = async () => {
    try {
      const data = await DeadlineChecklistService.getAllChecklistItems();
      setChecklistItems(data);
      setFilteredChecklistItems(data);
    } catch (error) {
      console.error("Error fetching checklist items:", error);
    }
  };

  const handleSearch = (search, filter) => {
    if (!search || !filter) {
      // Reset filtered data to full set based on active table
      resetFilteredData();
      return;
    }

    // Filter data based on the active table and columns for that table
    if (activeTable === "Tasks") {
      filterData(search, filter, tasks, taskColumns, setFilteredTasks);
    } else if (activeTable === "Deadlines") {
      filterData(search, filter, deadlines, deadlineColumns, setFilteredDeadlines);
    } else if (activeTable === "Checklist") {
      filterData(search, filter, checklistItems, checklistColumns, setFilteredChecklistItems);
    }
    setPage(1); // Reset to page 1 after search
  };

  const filterData = (search, filter, data, columns, setFilteredData) => {
    const filtered = data.filter((entry) =>
      entry[columns[filter]]?.toLowerCase().includes(search.toLowerCase())
    );
    console.log(search, filter, data, columns);
    setFilteredData(filtered);
  };

  const resetFilteredData = () => {
    if (activeTable === "Tasks") {
      setFilteredTasks(tasks);
    } else if (activeTable === "Deadlines") {
      setFilteredDeadlines(deadlines);
    } else if (activeTable === "Checklist") {
      setFilteredChecklistItems(checklistItems);
    }
  };

  const handlePagination = (page) => {
    setPage(page);
  };

  const paginatedData = (data = []) =>
    data.slice((page - 1) * PAGELIMIT, page * PAGELIMIT);

  const handleToggleTasks = (showMyTasks) => {
    setShowMyTasks(showMyTasks);
    setPage(1);  // Reset page to 1 when toggling task visibility
  };

  const renderTable = () => {
    switch (activeTable) {
      case "Tasks":
        return <TasksTable data={paginatedData(filteredTasks)} />;
      case "Deadlines":
        return <DeadlinesTable data={paginatedData(filteredDeadlines)} />;
      case "Checklist":
        return <ChecklistTable data={paginatedData(filteredChecklistItems)} />;
      default:
        return null;
    }
  };

  return (
    <div className="tasksPage">
      <TasksTopBar onSearch={handleSearch} onToggleTasks={handleToggleTasks} activeTable={activeTable} 
      columns={activeTable === "Tasks" ? taskColumns : activeTable === "Deadlines" ? deadlineColumns : checklistColumns} 
      />
      <DataBar setActiveTable={setActiveTable} />
      {renderTable()}
      <TasksPagination
        currPage={page}
        onPageChange={handlePagination}
        pageLimit={Math.ceil(
          activeTable === "Deadlines"
            ? filteredDeadlines.length / PAGELIMIT
            : activeTable === "Checklist"
            ? filteredChecklistItems.length / PAGELIMIT
            : filteredTasks.length / PAGELIMIT
        )}
      />
    </div>
  );
};

export default TasksPage;
