import { useQuery } from "@tanstack/react-query";
import FinancialService from "../../../../../AppSettings/services/FinancialService";
import Select from "react-select";
import styles from "./AddDisbursement.module.css";
import { useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import getAuthHeaders from "../../../../../AppSettings/routes/functions/getAuthHeaders";

const AddDisbursement = ({ onClose, onAddSuccess }) => {
  const { matterId } = useParams();

  const {
    data: disbursements,
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ["agentDisbursements"],
    queryFn: FinancialService.getAgentDisbursements,
  });

  const [agents, setAgents] = useState([]);
  const [agentsLoading, setAgentsLoading] = useState(true);
  const [agentsError, setAgentsError] = useState(null);

  const [newDisbursementData, setNewDisbursementData] = useState({
    agentid: "",
    description: "",
    fees: "",
    date_added: null,
  });

  const modalRef = useRef(null); // Modal reference to check if the click was outside the modal

  // Fetch agents logic
  useEffect(() => {
    const fetchAgents = async () => {
      try {
        setAgentsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/agents`,
          {
            headers: await getAuthHeaders(),
          }
        );
        const data = await response.json();
        const sortedAgents = data.sort((a, b) =>
          a.AgentID.localeCompare(b.AgentID)
        );
        setAgents(
          sortedAgents.map((agent) => ({
            value: agent.AgentID,
            label: `${agent.AgentID}: ${agent.FirstName} ${agent.LastName}`,
          }))
        );
      } catch (err) {
        setAgentsError("Error fetching agents");
        console.error("Error fetching agents:", err);
      } finally {
        setAgentsLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setNewDisbursementData({
      ...newDisbursementData,
      agentid: selectedOption ? selectedOption.value : "",
    });
  };

  const handleNewDisbursementSubmit = async (e) => {
    e.preventDefault();
    try {
      await FinancialService.addAgentDisbursement({
        ...newDisbursementData,
        matterid: matterId,
      });
      refetch();
      onAddSuccess(); // Notify parent component to refresh data
      setNewDisbursementData({
        agentid: "",
        description: "",
        fees: "",
        date_added: null,
      });
    } catch (err) {
      console.error("Error submitting new disbursement:", err);
    }
  };

  const handlePositiveNumberInput = (e) => {
    const value = e.target.value;
    setNewDisbursementData({
      ...newDisbursementData,
      fees: value && Math.abs(value) >= 0 ? Math.abs(value) : "",
    });
  };

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Close modal
      }
    };

    // Bind the click event
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (isLoading || agentsLoading) {
    return <div>Loading...</div>;
  }

  if (error || agentsError) {
    return <div>{error || agentsError}</div>;
  }

  const selectedAgent = agents.find(
    (option) => option.value === newDisbursementData.agentid
  );

  return (
    <div className={styles["Fixed-fees-overlay"]}>
      <div className={styles["Fixed-fees-modal"]} ref={modalRef}>
        <div className={styles["title-button-container"]}>
          <h2>Add Disbursement</h2>
        </div>

        <form
          className={styles["new-fee-form"]}
          onSubmit={handleNewDisbursementSubmit}
        >
          <div className={styles["form-group"]}>
            <Select
              options={agents}
              value={selectedAgent || null} // Bind the selected value
              onChange={handleSelectChange}
              placeholder="Select or search an agent..."
              isClearable
              isLoading={agentsLoading}
            />
          </div>

          <input
            type="text"
            className="input-field"
            placeholder="Description"
            value={newDisbursementData.description}
            onChange={(e) =>
              setNewDisbursementData({
                ...newDisbursementData,
                description: e.target.value,
              })
            }
            required
          />
          <input
            type="number"
            min="0"
            onInput={handlePositiveNumberInput}
            className="input-field"
            placeholder="Fees"
            value={newDisbursementData.fees}
            required
          />
          <input
            type="date"
            className="input-field"
            placeholder="Date Added (Optional)"
            value={newDisbursementData.date_added || ""}
            onChange={(e) =>
              setNewDisbursementData({
                ...newDisbursementData,
                date_added: e.target.value || null,
              })
            }
          />
          <button type="submit" className={styles["submit-fee-button"]}>
            Submit
          </button>
        </form>

        <div className="fixedFeesList">
          {disbursements.map((entry, index) => (
            <div key={index} className="fee-entry">
              <span>
                {entry.agentid} - {entry.description} - ${entry.fees}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddDisbursement;
