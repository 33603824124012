import React, { useEffect, useState } from 'react';
import './ProfilePage.css';
import getAuthHeaders from '../AppSettings/routes/functions/getAuthHeaders';
import { useMsal } from '@azure/msal-react';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Templates from './Components/Templates/Templates';
import ProfileWorkflow from './Components/Create&UpdateWorkflow/ProflieWorkflow';
import PersonalWidget from './Components/PersonalWidget/PersonalWidget';
import Rules from './Components/Rules/Rules';
import RulesList from './Components/ListofRules/RulesList';
const Profile = () => { 
  const { accounts } = useMsal();
  const [employee, setEmployee] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [newBilledRate, setNewBilledRate] = useState('');
  const [deadlineRules, setDeadlineRules] = useState([]);
  const [newRule, setNewRule] = useState({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
  
  // State for active section
  const [activeSection, setActiveSection] = useState('deadlineRules'); // Default section

  useEffect(() => {
    fetchEmployee();
    fetchDeadlineRulesTable();
  }, []);

  const fetchEmployee = async () => {
    try {
      const { oid } = accounts[0].idTokenClaims;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getemployee/${oid}`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      setEmployee(data);
    } catch (error) {
      console.log('Error fetching employee data:', error);
    }
  };

  

  const handleUpdateEmployee = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee/${employee.employeeid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ billedrate: newBilledRate })
      });
      if (response.ok) {
        setEmployee({ ...employee, billedrate: newBilledRate });
        setEditMode(false);
      } else {
        console.error('Failed to update billed rate');
      }
    } catch (error) {
      console.log('Error updating billed rate:', error);
    }
  };

  const fetchDeadlineRulesTable = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/deadline-rules`, {
        headers: await getAuthHeaders()
      });
      const data = await response.json();
      console.log('Fetched deadline rules:', data);
      setDeadlineRules(data);
    } catch (error) {
      console.log('Error fetching deadline rules:', error);
    }
  };

  const handleSaveDeadlineRuleToTable = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/save_deadline_rule`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...await getAuthHeaders()
        },
        body: JSON.stringify(newRule)
      });
      if (response.ok) {
        fetchDeadlineRulesTable(); // Refresh rules after saving
        setNewRule({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
      } else {
        console.error('Failed to save the deadline rule to the deadline_rules table');
      }
    } catch (error) {
      console.log('Error saving the deadline rule to the deadline_rules table:', error);
    }
  };

  const handleDeleteDeadlineRule = async (ruleId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/delete_deadline_rule/${ruleId}`, {
        method: 'DELETE',
        headers: await getAuthHeaders()
      });
      if (response.ok) {
        fetchDeadlineRulesTable(); // Refresh the rules after deletion
      } else {
        console.error('Failed to delete the deadline rule');
      }
    } catch (error) {
      console.log('Error deleting the deadline rule:', error);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-left">
        <div className="top-section">
          <PersonalWidget />
          <div className="rule-container"> <Rules /></div>
        
        </div>
        <RulesList />
      </div>
      <div className="profile-right">
        <div> <Templates /></div>
       <div>   <ProfileWorkflow /></div>
     
      </div>
    </div>

  );
};

export default Profile;






