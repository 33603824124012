import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react"; 
import { useParams } from "react-router-dom";
import "./MatterTasksPage.css";
import TasksTopBar from "./MatterTasks/Components/TopBar/TopBar.js"
import TasksTable from "./MatterTasks/Components/TaskTable/TasksTable";
import DeadlinesTable from "./MatterDeadline/Components/DeadlineTable/DeadlineTable";
import ChecklistTable from "./MatterChecklist/Components/ChecklistTable/ChecklistTable";
import TasksPagination from "./MatterTasks/Components/Pagination/Pagination";
import DataBar from "./MatterTasks/Components/DataBar/DataBar";
import TaskService from "../../../AppSettings/services/TaskService.js";
import DeadlineChecklistService from "../../../AppSettings/services/DeadlineChecklistService.js";

const PAGELIMIT = 12;

const columns = {
  Task: "task_title",
  Status: "status",
  Type: "task_type",
  "Assigned To": "assignedto",
  "Due Date": "duedate"
};

const TasksPage = () => {
  const { accounts } = useMsal();
  const { matterId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [deadlines, setDeadlines] = useState([]); 
  const [checklistItems, setChecklistItems] = useState([]); // State for checklist items
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [page, setPage] = useState(1);
  const [showMyTasks, setShowMyTasks] = useState(true);
  const [activeTable, setActiveTable] = useState("Tasks");

  const employeeId = accounts[0]?.idTokenClaims?.oid;

  useEffect(() => {
    if (employeeId) {
      fetchTasks();
    }
    if (activeTable === "Deadlines") {
      fetchDeadlines();
    } else if (activeTable === "Checklist") {
      fetchChecklistItems(); // Fetch checklist items when the Checklist table is active
    }
  }, [employeeId, showMyTasks, activeTable]);

  const fetchTasks = async () => {
    try {
      let data;
      if (showMyTasks && employeeId) {
        data = await TaskService.getTasksByMatterAndEmployee(matterId, employeeId)
        
      } else {
        data = await TaskService.getTasksByMatterId(matterId);
      }
      setTasks(data);
      setFilteredTasks(data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const fetchDeadlines = async () => {
    try {
      const data = await DeadlineChecklistService.getDeadlinesByMatterId(matterId);
      setDeadlines(data);
    } catch (error) {
      console.error("Error fetching deadlines:", error);
    }
  };

  const fetchChecklistItems = async () => {
    try {
        const data = await DeadlineChecklistService.getChecklistItemsByMatterId(matterId);
        console.log("Checklist Data:", data); // Debugging line
        setChecklistItems(data);
    } catch (error) {
        console.error("Error fetching checklist items:", error);
    }
};


  const handleSearch = (search, filter) => {
    if (!filter) {
      return;
    }

    const filtered = tasks.filter((entry) =>
      entry[columns[filter]].toLowerCase().includes(search.toLowerCase())
    );
    setFilteredTasks(filtered);
  };

  const handlePagination = (page) => {
    setPage(page);
  };

  const paginatedData = (data = []) =>
    data.slice((page - 1) * PAGELIMIT, page * PAGELIMIT);

  const handleToggleTasks = (showMyTasks) => {
    setShowMyTasks(showMyTasks);
    setPage(1);
  };

  const renderTable = () => {
    switch (activeTable) {
      case "Tasks":
        return <TasksTable data={paginatedData(filteredTasks)} />;
      case "Deadlines":
        return <DeadlinesTable data={paginatedData(deadlines)} />;
      case "Checklist":
        return <ChecklistTable data={paginatedData(checklistItems)} />; // Render checklist items
      default:
        return null;
    }
  };

  return (
    <div className="tasksPage">
      <TasksTopBar onSearch={handleSearch} onToggleTasks={handleToggleTasks} />
      <DataBar setActiveTable={setActiveTable} />
      {renderTable()}
      <TasksPagination
        currPage={page}
        onPageChange={handlePagination}
        pageLimit={Math.ceil(
          activeTable === "Deadlines"
            ? deadlines.length / PAGELIMIT
            : activeTable === "Checklist"
            ? checklistItems.length / PAGELIMIT
            : filteredTasks.length / PAGELIMIT
        )}
      />
    </div>
  );
};

export default TasksPage;
