import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import "./ChecklistTable.css";
import ChecklistSideBar from './ChecklistSideBar/ChecklistSideBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DeadlineChecklistService from '../../../../../../AppSettings/services/DeadlineChecklistService';

const ChecklistTable = ({ data }) => {
  const { matterId } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [checklistData, setChecklistData] = useState(data); // Local state to reflect changes instantly


  useEffect(() => {
    setChecklistData(data || []);
  }, [data]);

  const openSidebar = (checklist) => {
    setSelectedChecklist(checklist);
    setIsSidebarOpen(true);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
    setSelectedChecklist(null);
  };

  const handleStatusToggle = async (checklistId, currentStatus) => {
    if (!checklistId) {
      console.error('Checklist ID is undefined');
      return;
    }

    const newStatus = !currentStatus; // Toggle the status optimistically

    // Optimistically update the state
    setChecklistData((prevData) =>
      prevData.map((item) =>
        item.checklistid === checklistId ? { ...item, status: newStatus } : item
      )
    );

    try {
      // Call the service to update the status on the backend
      const response = await DeadlineChecklistService.updateChecklistItemStatus(checklistId, newStatus);
      console.log(response.message); // Log success message
    } catch (error) {
      console.error(`Failed to update status for checklist item ID ${checklistId}:`, error);

      // Revert the state if the API call fails
      setChecklistData((prevData) =>
        prevData.map((item) =>
          item.checklistid === checklistId ? { ...item, status: currentStatus } : item
        )
      );
    }
  };

  const truncateTitle = (title) => {
    if (!title || typeof title !== 'string') {
      return ""; // Return an empty string if title is invalid.
    }
    const words = title.split(" ");
    if (words.length > 20) {
      return words.slice(0, 20).join(" ") + " ..."; // Truncate to 20 words.
    }
    return title;
  };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th>Status</th>
            <th>Description</th>
            <th className="due-date-header">
              <span>Matter ID</span>
              <Link to={`/matterDetails/${matterId}/tasks/createChecklist`} className="add-icon-link">
                <FontAwesomeIcon icon={faPlus} className="icon-button" aria-label="Add" />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {checklistData.map((row, index) => (
            <tr key={index}>
              <td>
                <label className="Checklist-checkbox-container">
                  <input
                    type="checkbox"
                    checked={row.status}
                    onChange={() => handleStatusToggle(row.checklistid, row.status)}
                  />
                  <span className="Checklist-checkbox"></span>
                </label>
              </td>

              <td>
                <button
                  className="TaskLink"
                  title={row.description}
                  onClick={() => openSidebar(row)}
                >
                  {truncateTitle(row.description)}
                </button>
              </td>
              <td>{row.matterid}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ChecklistSideBar checklist={selectedChecklist} onClose={closeSidebar} isOpen={isSidebarOpen} />
    </div>
  );
};

export default ChecklistTable;
