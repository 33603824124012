import React, { useEffect, useState } from 'react';
import './DeadlineGraph.css';
import DeadlineChecklistService from '../../../../../AppSettings/services/DeadlineChecklistService';

const DeadlineGraph = () => {
  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]); // Placeholder data for the bars
  const days = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  useEffect(() => {
    // Fetch the current week's summary when the component mounts
    const fetchWeekSummary = async () => {
      try {
        const weekSummary = await DeadlineChecklistService.getCurrentWeekSummary();
        const dayTotals = Array(7).fill(0); // Initialize totals for each day

        weekSummary.forEach((entry) => {
          const date = new Date(entry.date);
          const dayIndex = date.getDay(); // Get day of the week (0 = Sunday, 1 = Monday, ...)
          dayTotals[dayIndex] = entry.total; // Update the total for the corresponding day
        });

        setData(dayTotals); // Update the state with fetched totals
      } catch (error) {
        console.error('Error fetching week summary:', error);
      }
    };

    fetchWeekSummary();
  }, []);

  // Determine the maximum value for scaling
  const maxValue = Math.max(...data);

  return (
    <div className="DC-graph-container">
      {data.map((value, index) => (
        <div key={index} className="DC-column">
          <div className="DC-bar-container">
            <div className="DC-ball"></div> {/* Ball above bar */}
            <div
              className="DC-bar"
              style={{
                height: maxValue > 0 ? `${(value / maxValue) * 190}px` : '0px',
              }}
            ></div> {/* Bar */}
          </div>
          <div className="DC-day-label">{days[index]}</div>
        </div>
      ))}
    </div>
  );
};

export default DeadlineGraph;
