import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import IconButton from "../../../../Components/TopBar/Components/IconButton";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import MatterService from "../../../../../AppSettings/services/MatterService";
import { useQuery } from "@tanstack/react-query";

const FinancialDetailsTopBar = ({ setActivePage }) => {
  const { matterId } = useParams();
  const [matterName, setMatterName] = useState(null);

  const { data, isLoading, error } = useQuery({
    queryKey: ["matterName", matterId],
    queryFn: () => MatterService.getMatterName(matterId),
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      setMatterName(data[0]);
    }
  }, [data]);

  const [menuOpen, setMenuOpen] = useState(false); // Track menu visibility
  const menuRef = useRef(null); // Ref to track the menu container

  const toggleMenu = () => setMenuOpen((prev) => !prev); // Toggle menu visibility

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading matter name</div>;

  return (
    <div className="financialDetailsTopBar">
      <div id="leftSidefinancialDetailsTopBar">
        <Link to={`/matterDetails/${matterId}`}>
          <button className="financialDetailsBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="financialDetailsBackButton" />
          </button>
        </Link>
        <span>{matterId} {matterName}</span>
      </div>

      <div id="rightSidefinancialDetailsTopBar" ref={menuRef}>
        <IconButton icon={faBars} ariaLabel="Bars" onClick={toggleMenu} />

        {menuOpen && (
          <div className="financial-dropdown-menu">
            <button onClick={() => setActivePage("dashboard")}>Dashboard</button>
            {/* <button onClick={() => setActivePage("invoice")}>Invoice</button> */}
            <button onClick={() => setActivePage("fixedFees")}>Fixed Fees</button>
            <button onClick={() => setActivePage("disbursements")}>Disbursements</button>
            <button onClick={() => setActivePage("billableTime")}>Billable Time</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinancialDetailsTopBar;

