import React, { useState, useEffect } from 'react';
import './BillableTable.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

const BillableTable = ({ data = [] }) => {
    const [pageLimit, setPageLimit] = useState(1);
    const [currPage, setCurrPage] = useState(1);

    useEffect(() => {
        if (data) {
            setPageLimit(Math.max(1, Math.ceil(data.length / 5)));
        }
    }, [data]);

    const handlePageDecrement = () => {
        if (currPage > 1) {
            setCurrPage(currPage - 1);
        }
    };

    const handlePageIncrement = () => {
        if (currPage < pageLimit) {
            setCurrPage(currPage + 1);
        }
    };

    return (
        <div className="billableTable-container">

            <div className="Bill-table-container">
                <table className="Bill-table">
                    <thead>
                        <tr>
                            <th>Employee</th>
                            <th>Billed Rate ($/hr)</th>
                            <th>Time Recorded</th>
                            <th>Date</th>
                            <th>Notes</th>
                            <th>Billed Charge</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.employee_name}</td>
                                    <td>{`$${row.billedrate}`}</td>
                                    <td>{row.time_recorded} hours</td>
                                    <td>{row.date ? new Date(row.date).toLocaleDateString() : "invalid date"}</td>
                                    <td>{row.notes}</td>
                                    <td>{`$${isNaN(row.amount) ? 0 : row.amount}`}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="Bill-footer">
                <button
                    className="Bill-pagination-button"
                    onClick={handlePageDecrement}
                >
                    <FontAwesomeIcon icon={faAnglesLeft} />
                </button>
                <span>
                    {currPage} of {pageLimit}
                </span>
                <button
                    className="Bill-pagination-button"
                    onClick={handlePageIncrement}
                >
                    <FontAwesomeIcon icon={faAnglesRight} />
                </button>
            </div>
        </div>
    );
};

export default BillableTable;
