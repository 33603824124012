import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import MatterService from "../../../../../../AppSettings/services/MatterService";
import DeadlineChecklistService from "../../../../../../AppSettings/services/DeadlineChecklistService";
import "./AddDC.css";

const AddDC = () => {
  const { accounts } = useMsal();
  const { matterId } = useParams();
  const { oid } = accounts[0].idTokenClaims;

  const [formData, setFormData] = useState({
    deadline: {
      deadline_type: "",
      type: "",
      due_date: "",
      matterid: matterId,
      status: false,
      internal_external: "",
    },
    checklist: {
      checklistid: "",
      description: "",
      status: "Pending",
      matterid: matterId,
    },
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [matters, setMatters] = useState([]);
  const [loadingMatters, setLoadingMatters] = useState(true);
  const [isDeadlineMode, setIsDeadlineMode] = useState(true); // Toggle state

  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const data = await MatterService.getMatters();
        const options = data.map((matter) => ({
          value: matter.matterid,
          label: `${matter.matterid}: ${matter.mattername}`,
        }));
        setMatters(options);
      } catch (error) {
        console.error("Error fetching matters:", error);
        setError("Failed to load matters.");
      } finally {
        setLoadingMatters(false);
      }
    };

    fetchMatters();
  }, []);

  const handleInputChange = (e, mode) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [mode]: {
        ...prevState[mode],
        [name]: value,
      },
    }));
  };

  const handleMatterChange = (selectedOption, mode) => {
    setFormData((prevState) => ({
      ...prevState,
      [mode]: {
        ...prevState[mode],
        matterid: selectedOption ? selectedOption.value : "",
      },
    }));
  };

  const handleCreation = async () => {
    setLoading(true);
    setError("");

    try {
      const mode = isDeadlineMode ? "deadline" : "checklist";
      const response = isDeadlineMode
        ? await DeadlineChecklistService.addDeadline(formData.deadline)
        : await DeadlineChecklistService.addChecklistItem(formData.checklist);

      if (response.error) throw new Error(response.error);

      alert(`${isDeadlineMode ? "Deadline" : "Checklist item"} created successfully!`);

      setFormData((prevState) => ({
        ...prevState,
        [mode]: {
          ...(isDeadlineMode
            ? {
                deadline_type: "",
                type: "",
                due_date: "",
                matterid: matterId,
                status: false,
                internal_external: "",
              }
            : {
                checklistid: "",
                description: "",
                status: "Pending",
                matterid: matterId,
              }),
        },
      }));
    } catch (error) {
      console.error("Error creating item:", error);
      setError(`Failed to create the ${isDeadlineMode ? "deadline" : "checklist item"}. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  return (
<div className="AddDC-Page">
  <div className="DC-form-container">
    {/* Header with toggle button */}
    <div className="DC-Header">
      <div className="DC-Title">
        <h2>{isDeadlineMode ? "Create Deadline" : "Create Checklist Item"}</h2>
      </div>
      <button
        className="DC-toggle-button"
        onClick={() => setIsDeadlineMode(!isDeadlineMode)}
      >
 {isDeadlineMode ? "Checklist" : "Deadline"} Creation
      </button>
    </div>

    {/* Form Content */}
    {isDeadlineMode ? (
      <div className="DC-Deadline-form">
        <div className="DC-Deadline-form">
          <label>Deadline Type</label>
          <input
            type="text"
            name="deadline_type"
            placeholder="Enter deadline type"
            value={formData.deadline.deadline_type}
            onChange={(e) => handleInputChange(e, "deadline")}
          />
        </div>

        <div className="DC-Deadline-form">
          <label>Type</label>
          <input
            type="text"
            name="type"
            placeholder="Enter type"
            value={formData.deadline.type}
            onChange={(e) => handleInputChange(e, "deadline")}
          />
        </div>

        <div className="DC-Deadline-form">
          <label>Due Date</label>
          <input
            type="date"
            name="due_date"
            value={formData.deadline.due_date}
            onChange={(e) => handleInputChange(e, "deadline")}
          />
        </div>

        <div className="DC-Deadline-form">
          <label>Internal or External</label>
          <input
            type="text"
            name="internal_external"
            placeholder="Enter 'internal' or 'external'"
            value={formData.deadline.internal_external}
            onChange={(e) => handleInputChange(e, "deadline")}
          />
        </div>
      </div>
    ) : (
      <div className="Checklist-form">
        <div className="form-group">
          <label>Description</label>
          <textarea
            name="description"
            placeholder="Enter checklist description"
            value={formData.checklist.description}
            onChange={(e) => handleInputChange(e, "checklist")}
          />
        </div>

        <div className="form-group">
          <label>Status</label>
          <select
            name="status"
            value={formData.checklist.status}
            onChange={(e) => handleInputChange(e, "checklist")}
          >
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
          </select>
        </div>
      </div>
    )}

    {/* Error and Loading States */}
    {error && <div className="error-message">{error}</div>}
    {loading && <div className="loading-message">Processing...</div>}

    {/* Submit Button */}
    <button
      className="DC-submit-button"
      onClick={handleCreation}
      disabled={loading}
    >
      {loading ? "Submitting..." : "Create"}
    </button>
  </div>
</div>

  );
};

export default AddDC;
