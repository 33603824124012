import { useState, useEffect } from "react";
import { useMsal } from '@azure/msal-react';
import TopBar from "./Components/TopBar";
import Select from "react-select";
import TaskService from "../../../AppSettings/services/TaskService";
import MatterService from "../../../AppSettings/services/MatterService";
import EmployeeService from "../../../AppSettings/services/EmployeeService"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import "./CreateTasksPage.css";

const CreateTasksPage = () => {
    const { accounts } = useMsal();
    const { oid } = accounts[0].idTokenClaims;

    const taskTypes = [{value: "Internal", label: "Internal"}, {value: "External", label: "External"}];

    const [formData, setFormData] = useState({
        matterid: "",
        task_type: "",
        assignedto: "",
        duedate: "",
        description: "",
        task_title: "",
        status: "Pending", // Default status
        createdby: oid, // Assume creator will be assigned later or dynamically
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [matters, setMatters] = useState([]);
    const [employees, setEmployees] = useState([]); // Store fetched employees
    const [loadingMatters, setLoadingMatters] = useState(true);
    const [loadingEmployees, setLoadingEmployees] = useState(true);

    useEffect(() => {
        const fetchMatters = async () => {
            try {
                const data = await MatterService.getMatters();
                const options = data.map((matter) => ({
                    value: matter.matterid,
                    label: `${matter.matterid}: ${matter.mattername}`,
                }));
                setMatters(options);
            } catch (error) {
                console.error("Error fetching matters:", error);
                setError("Failed to load matters.");
            } finally {
                setLoadingMatters(false);
            }
        };

        const fetchEmployees = async () => {
            try {
                const data = await EmployeeService.getEmployees(); // Fetch employees
                const options = data.map((employee) => ({
                  value: employee.EmployeeID,
                  label: `${employee.FirstName} ${employee.LastName}`,
                }))
                console.log(options);
                setEmployees(options);
            } catch (error) {
                console.error("Error fetching employees:", error);
                setError("Failed to load employees.");
            } finally {
              setLoadingEmployees(false);
            }
        };

        fetchMatters();
        fetchEmployees(); // Fetch employees on component mount
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleMatterChange = (selectedOption) => {
        setFormData({ ...formData, matterid: selectedOption ? selectedOption.value : "" });
    };

    const handleTaskTypeChange = (selectedOption) => {
        setFormData({ ...formData, task_type: selectedOption ? selectedOption.value : "" });
    };

    const handleAssignedToChange = (selectedOption) => {
        setFormData({ ...formData, assignedto: selectedOption ? selectedOption.value : "" });
    };

    const handleTaskCreation = async () => {
        setLoading(true);
        setError("");

        try {
            // Simulate task creation (you can call the actual TaskService.addTask here)
            console.log(formData);
            const response = await TaskService.addTask(formData);
            if (response.error) throw new Error(response.error);

            alert("Task added successfully!");
            
            // Clear all Select components by resetting the formData state
            setFormData({
                matterid: "",
                task_type: "",
                assignedto: "",
                duedate: "",
                description: "",
                task_title: "",
                status: "Pending",
                createdby: oid,
            });
        } catch (error) {
            console.error("Error adding task:", error);
            setError("Failed to add the task. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="Tasks-Page">
            <TopBar formData={formData} handleTaskCreation={handleTaskCreation} />
            <div className="Tasks-entire-form-container" style={{ justifyContent: "center" }}>
                <div className="Tasks-form-container">
                    <div className="Tasks-form-group">
                        <label className="Tasks-form-group-label">Task Title</label>
                        <div className="Tasks-input-icon">
                            <input
                                type="text"
                                name="task_title"
                                placeholder="Enter task title"
                                value={formData.task_title}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="Tasks-form-group">
                        <label className="Tasks-form-group-label">Matter ID</label>
                        <Select
                            options={matters}
                            value={matters.find((matter) => matter.value === formData.matterid) || null}
                            onChange={handleMatterChange}
                            isLoading={loadingMatters}
                            placeholder="Select or search a matter..."
                            isClearable
                        />
                    </div>

                    <div className="Tasks-form-group">
                        <label className="Tasks-form-group-label">Assign Leader</label>
                        <Select
                            options={employees}
                            isLoading={loadingEmployees}
                            value={employees.find((emp) => emp.value === formData.assignedto) || null}
                            onChange={handleAssignedToChange}
                            placeholder="Select or search an employee..."
                            isClearable
                        />
                    </div>

                    <div style={{ paddingBottom: "1em" }}>
                        <div className="Tasks-item2">
                            <label className="Tasks-form-group-label">Due Date</label>
                            <div className="Tasks-input-icon">
                                <input
                                    type="date"
                                    name="duedate"
                                    value={formData.duedate}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="Tasks-form-group">
                        <label className="Tasks-form-group-label">Description</label>
                        <textarea
                            name="description"
                            placeholder="Type the task description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="Tasks-form-group">
                        <label className="Tasks-form-group-label">Task Type</label>
                        <Select
                            options={taskTypes}
                            value={taskTypes.find((type) => type.value === formData.task_type) || null}
                            onChange={handleTaskTypeChange}
                            placeholder="Select or search a task type..."
                            isClearable
                        />
                    </div>

                    {error && <div className="error-message">{error}</div>}
                    {loading && <div className="loading-message">Adding task...</div>}
                </div>
            </div>
        </div>
    );
};

export default CreateTasksPage;
