import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import "./InvoiceView.css"; // Assuming you'll add the styles in an external CSS file

function InvoiceView({
  client,
  matterId,
  matterName,
  application_no,
  dueDate,
  lineItems,
  discountRate,
  taxRate,
  country,
}) {
  let totalAmount = 0;
  let totalDiscount = 0;
  lineItems.forEach((item) => {
    const amount = parseFloat(item.amount) || 0;
    const discount = parseFloat(item.discount) || 0;
    const quantity = parseInt(item.qty) || 1;
    const discountAmount = (amount * discount) / 100;
    const finalAmount = amount - discountAmount;
    totalAmount += finalAmount * quantity;
    totalDiscount += discountAmount * quantity;
  });

  const subtotalAfterDiscount = totalAmount - totalDiscount;
  const taxAmount = subtotalAfterDiscount * (taxRate / 100);
  const finalTotal = subtotalAfterDiscount + taxAmount;

  if (dueDate) {
    const dueDateArray = dueDate.split("-");
    dueDate = `${dueDateArray[2]}/${dueDateArray[1]}/${dueDateArray[0]}`;
  }

  return (
    <div>
      <div className="invoice-container">
        <div className="preview-invoice">*PREVIEW OF INVOICE</div>
        {/* Invoice Header */}
        <div className="invoice-header">
          <div className="logo">
            {/* Logo here */}
            {/* <img src="logo.png" alt="Logo" width="100" /> */}
          </div>
          <div className="invoice-title">INVOICE</div>
        </div>
        {/* From Section */}
        <div className="from-section">
          <p>
            <strong>From:</strong> FILFIL IP - Rami Filfil
          </p>
          <p>
            <strong>Matter Name:</strong> {matterName} {matterId}
          </p>
          <p>
            {application_no ? (
              <>
                <strong>Application No:</strong> {application_no}
              </>
            ) : (
              <></>
            )}
          </p>
          <p>850 Sendero Way, Ottawa ON, K2S 2W9</p>
          <p>(613) 909-8444</p>
          <p>{country}</p>
          <p>
            <strong>Date:</strong> {new Date().toLocaleDateString("en-GB")}
          </p>
          <p>
            <strong>Due Date:</strong> {dueDate}
          </p>
        </div>
        {/* Billed To Section */}
        <div className="billed-to">
          <p>
            <strong>Billed To:</strong>
          </p>
          <p>{client.name}</p>
          <p>{client.email}</p>
          <p>{client.company}</p>
          {client.address ? (
            <p>
              <strong>Address:</strong> {client.address}
            </p>
          ) : (
            <></>
          )}
          {client.phone ? (
            <p>
              <strong>Phone:</strong> {client.phone}
            </p>
          ) : (
            <></>
          )}
          {client.country && <p>{client.country}</p>}
        </div>
        {/* Table Header */}
        <table className="invoice-table">
          <thead>
            <tr>
              <th>DESCRIPTION</th>
              <th>UNIT PRICE</th>
              <th>QTY</th>
              <th>DISCOUNT</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((item, index) => (
              <tr key={index}>
                <td>{item.description}</td>
                <td>{`$${parseFloat(item.amount).toFixed(2)}`}</td>
                <td>{item.qty}</td>
                <td>{`$${((item.amount * item.discount) / 100).toFixed(
                  2
                )}`}</td>
                <td>{`$${
                  (item.amount - (item.amount * item.discount) / 100) * item.qty
                }`}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Totals Section */}
        <div className="totals">
          <p>
            <strong>Subtotal:</strong> ${totalAmount.toFixed(2)}
          </p>
          <p>
            <strong>Discount:</strong> -${totalDiscount.toFixed(2)}
          </p>
          <p>
            <strong>Tax ({taxRate}%):</strong> ${taxAmount.toFixed(2)}
          </p>
          <p>
            <strong>Total:</strong> ${finalTotal.toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
}

export default InvoiceView;
