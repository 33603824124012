import "./TopBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft, faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import IconButton from "../../Components/TopBar/Components/IconButton";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import MatterService from "../../../AppSettings/services/MatterService";
import MatterProgressBar from "./Progressbar/MatterProgressbar";

const MatterDetailsTopBar = ({ setActivePage }) => {
  const { matterId } = useParams();
  const navigate = useNavigate();
  const [matterName, setMatterName] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // Track menu visibility
  const menuRef = useRef(null); // Ref to track the menu container

  const toggleMenu = () => setMenuOpen((prev) => !prev); // Toggle menu visibility

  useEffect(() => {
    fetchMatterName();
  }, [matterId]);

  const fetchMatterName = async () => {
    const response = await MatterService.getMatterName(matterId);
    setMatterName(response);
  };

  // Close the menu if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="MatterDetailsTopBar">
      <div id="leftSideMatterDetailsTopBar">
        <Link to="/matters">
          <button className="MatterDetailsBackButton">
            <FontAwesomeIcon icon={faAnglesLeft} className="MatterDetailsBackButton" />
          </button>
        </Link>
        <span>{matterId} {matterName}</span>
      </div>

      <div id="rightSideMatterDetailsTopBar" ref={menuRef}>
        < MatterProgressBar  />
        <IconButton icon={faBars} ariaLabel="Bars" onClick={toggleMenu} />

        {menuOpen && (
          <div className="Matter-dropdown-menu">
            <button onClick={() => navigate(`/matterDetails/${matterId}`)}>General</button>
            <button onClick={() => navigate(`/matterDetails/${matterId}/tasks`)}>Tasks</button>
            <button onClick={() => navigate(`/matterDetails/${matterId}/financial`)}>Financial</button>
            <button onClick={() => navigate(`/matterDetails/${matterId}/files`)}>Files</button>
            <button onClick={() => navigate(`/matterDetails/${matterId}/additionalNotes`)}>Notes</ button>
          </div>
        )}
      </div>
    </div>

  );
};

export default MatterDetailsTopBar;
