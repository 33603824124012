import getAuthHeaders from '../routes/functions/getAuthHeaders';
const API_URL = process.env.REACT_APP_API_BASE_URL; 

class ClientService {
    // Get all clients 
    static async getClients() {
        try {
            const response = await fetch(`${API_URL}/clients`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            const sortedClients = data.sort((a, b) => a.ClientID.localeCompare(b.ClientID));
            return sortedClients;
          } catch (error) {
            console.log('Error fetching clients:', error);
          }
    }

    // Post a new client
    static async postClient(client) {
      if (!API_URL) {
        throw new Error("API base URL is not defined");
      }
      
      try {
        const response = await fetch(`${API_URL}/clients`, {
          method: 'POST',
          headers: await getAuthHeaders(),
          body: JSON.stringify(client),
        });

        if (!response.ok) {
          const errorData = await response.json();  // Assuming the server responds with JSON error data
          const error = new Error(`Error posting client: ${response.statusText}`);
          error.response = {
            status: response.status,
            statusText: response.statusText,
            data: errorData,  // Attach the error data returned by the server
          };
          throw error; // This will be caught in React Query's onError
        }

        // If successful, return true or data
        return response.status === 201;
        
      } catch (error) {
        // If there's a network error or any other error, re-throw it to be caught by React Query's onError
        throw error;
      }
  }

    // Get client count
    static async getClientCount() {
      try {
        const response = await fetch(`${API_URL}/clients/count`, {
          headers: await getAuthHeaders()
        });
        const data = await response.json();
        return data.count;
      } catch (error) {
        console.log('Error fetching client count:', error);
      }
    }

    static async getClientById(clientId) {
      try {
          const response = await fetch(`${API_URL}/getclient/${clientId}`, {
              headers: await getAuthHeaders()
          });
          if (!response.ok) {
              throw new Error('Client not found');
          }
          const clientData = await response.json();
          return clientData;
      } catch (error) {
          console.error('Error fetching client details:', error);
          return null;
      }
  }

    // Get tasks by client ID
    static async getTasksByClient(clientId) {
        try {
            const response = await fetch(`${API_URL}/tasks_by_client/${clientId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Failed to fetch tasks for client');
            }
            const tasksData = await response.json();
            return tasksData;
        } catch (error) {
            console.error('Error fetching tasks by client:', error);
            return null;
        }
    }

    // Get deadlines by client ID
    static async getDeadlinesByClient(clientId) {
        try {
            const response = await fetch(`${API_URL}/deadlines_by_client/${clientId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Failed to fetch deadlines for client');
            }
            const deadlinesData = await response.json();
            return deadlinesData;
        } catch (error) {
            console.error('Error fetching deadlines by client:', error);
            return null;
        }
    }

    // Get count of client matters by client ID
    static async getClientMattersCount(clientId) {
        try {
            const response = await fetch(`${API_URL}/count_client_matters/${clientId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Failed to fetch client matters count');
            }
            const data = await response.json();
            return data.count;
        } catch (error) {
            console.error('Error fetching client matters count:', error);
            return null;
        }
    }

    // Get count of tasks by client ID
    static async getTasksCountByClient(clientId) {
        try {
            const response = await fetch(`${API_URL}/tasks_by_client/count/${clientId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Failed to fetch tasks count');
            }
            const data = await response.json();
            return data.count;
        } catch (error) {
            console.error('Error fetching tasks count:', error);
            return null;
        }
    }

    // Get count of deadlines by client ID
    static async getDeadlinesCountByClient(clientId) {
        try {
            const response = await fetch(`${API_URL}/deadlines_by_client/count/${clientId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Failed to fetch deadlines count');
            }
            const data = await response.json();
            return data.count;
        } catch (error) {
            console.error('Error fetching deadlines count:', error);
            return null;
        }
    }
}

export default ClientService;