import { useQuery } from "@tanstack/react-query";
import FinancialService from "../../../AppSettings/services/FinancialService";
import MatterService from "../../../AppSettings/services/MatterService";
import "./GeneralAddFixedFees.css";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";

const GeneralAddFixedFees = ({ onClose, onAddSuccess }) => {
  const { data: fixedFeesData, isLoading, error, refetch } = useQuery({
    queryKey: ["fixedFees"],
    queryFn: FinancialService.getFixedFees,
  });

  const { data: matters, isLoading: isMattersLoading } = useQuery({
    queryKey: ["matters"],
    queryFn: MatterService.getMatters,
  });

  const [isAddingNewFee, setIsAddingNewFee] = useState(false);
  const [newFeeData, setNewFeeData] = useState({
    fee_code: "",
    narrative: "",
    fees: "",
    
  });

  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleAddFee = async (entry) => {
    try {
      await FinancialService.addAssignedFixedFees({
        ...entry,
        matterId: "",
      });
      onAddSuccess();
      onClose();
    } catch (err) {
      console.error("Error adding fixed fee:", err);
    }
  };

  const handleNewFeeSubmit = async (e) => {
    e.preventDefault();
    console.log(newFeeData);
    await FinancialService.addFixedFee(newFeeData);
    refetch();
    setNewFeeData({
      fee_code: "",
      narrative: "",
      fees: "",
      matterId: "",
    });
  };

  const handlePositiveNumberInput = (e) => {
    const value = e.target.value;
    setNewFeeData({
      ...newFeeData,
      fees: value && Math.abs(value) >= 0 ? Math.abs(value) : null,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setNewFeeData({ ...newFeeData, matterId: selectedOption.value });
  };

  if (isLoading || isMattersLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching existing fixed fees</div>;
  }

  const matterOptions = matters.map((matter) => ({
    value: matter.matterid,
    label: `${matter.matterid} - ${matter.mattername}`,
  }));

  return (
    <div className="Fixed-fees-overlay">
      <div className="Fixed-fees-modal" ref={modalRef}>
        <div className="title-button-container">
          <h2>Add Fixed Fee</h2>
          <button
            className="new-fee-button"
            onClick={() => setIsAddingNewFee(!isAddingNewFee)}
          >
            {isAddingNewFee ? (
              <>Hide Create New Fixed Fee</>
            ) : (
              <>Create New Fixed Fee</>
            )}
          </button>
        </div>

        {isAddingNewFee && (
          <form className="new-fee-form" onSubmit={handleNewFeeSubmit}>
   
            <input
              type="text"
              placeholder="Fee Code"
              value={newFeeData.fee_code}
              onChange={(e) =>
                setNewFeeData({ ...newFeeData, fee_code: e.target.value })
              }
              required
            />
            <input
              type="text"
              placeholder="Narrative"
              value={newFeeData.narrative}
              onChange={(e) =>
                setNewFeeData({ ...newFeeData, narrative: e.target.value })
              }
              required
            />
            <input
              type="number"
              min="0"
              onInput={handlePositiveNumberInput}
              placeholder="Fees"
              value={newFeeData.fees}
              required
            />
            <button type="submit" className="submit-fee-button">
              Submit
            </button>
          </form>
        )}

        <div className="fixedFeesList">
                 <Select
              options={matterOptions}
              onChange={handleSelectChange}
              placeholder="Select Matter"
              isClearable
              required
            />
          {fixedFeesData.map((entry, index) => (
            <div key={index} className="fee-entry">
              <span>
                {entry.fee_code} - {entry.narrative} - ${entry.fees}
              </span>
              <button
                className="add-fee-button"
                onClick={() => handleAddFee(entry)}
              >
                +
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GeneralAddFixedFees;
