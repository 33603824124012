import React, { useState, useEffect, useRef } from 'react';
import './FDCalendar.css'; // Import the CSS for styling
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, isSameMonth, isSameDay } from 'date-fns';
import TaskService from '../../../AppSettings/services/TaskService';
import DeadlineChecklistService from '../../../AppSettings/services/DeadlineChecklistService';
import FinancialService from '../../../AppSettings/services/FinancialService';
import { useParams } from 'react-router-dom';

const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tasks, setTasks] = useState([]); // Initialize tasks as an empty array
    const [popupTasks, setPopupTasks] = useState([]); // Tasks to display in popup
    const [isPopupVisible, setIsPopupVisible] = useState(false); // Show/hide popup

    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupVisible(false);
            }
        };

        if (isPopupVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPopupVisible]);

    useEffect(() => {
        fetchTasksForMonth(); // Fetch tasks without matterId
    }, [currentMonth]);

    // Convert GMT to local time before rendering
    const formatLocalDate = (dateStr) => {
        if (!dateStr) return '';
        const gmtDate = new Date(dateStr);
        const localDate = new Date(
            gmtDate.getUTCFullYear(),
            gmtDate.getUTCMonth(),
            gmtDate.getUTCDate(),
            gmtDate.getUTCHours(),
            gmtDate.getUTCMinutes(),
            gmtDate.getUTCSeconds()
        );
        return localDate.toLocaleDateString(undefined, {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };

    const fetchTasksForMonth = async () => {
        const month = format(currentMonth, 'yyyy-MM'); // Format the month as 'YYYY-MM'
        try {
            // Fetch all billable times for the month
            const fetchedBilledTimes = await FinancialService.getAllBilledTimeByMonth(month);
            const validBilledTimes = Array.isArray(fetchedBilledTimes) ? fetchedBilledTimes : [];

            // Set tasks to only include billable times
            const combinedData = validBilledTimes.map(item => ({
                description: `${item.employee_name} - ${item.notes}`,
                duedate: item.date,
                amount: item.amount,
                type: "billed_time"
            }));

            setTasks(combinedData);
        } catch (error) {
            console.error('Error fetching billable times:', error);
            setTasks([]);
        }
    };
        

    const renderHeader = () => {
        return (
            <div className="FD-calendar-header">
                <button className="FD-calendar-header-button" onClick={prevMonth}>
                    <FaChevronLeft />
                </button>
                <span className="FD-calendar-header-title">
                    {format(currentMonth, 'MMMM yyyy')}
                </span>
                <button className="FD-calendar-header-button" onClick={nextMonth}>
                    <FaChevronRight />
                </button>
            </div>
        );
    };

    const renderDays = () => {
        const days = [];
        const dateFormat = 'EEE'; // Short day name format (e.g., 'Mon')

        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="FD-calendar-day-name" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="FD-calendar-days-row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
    
        const rows = [];
        let days = [];
        let day = startDate;
        const dateFormat = 'd';
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(day, dateFormat);
                const cloneDay = day;
    
                // Filter only billable times for this day
                const tasksForDay = tasks.filter(task => {
                    const taskDate = new Date(task.duedate);
                    const localTaskDate = new Date(
                        taskDate.getUTCFullYear(),
                        taskDate.getUTCMonth(),
                        taskDate.getUTCDate()
                    );
                    return isSameDay(localTaskDate, cloneDay);
                });
    
                const hasBilledTime = tasksForDay.some(task => task.type === 'billed_time');
    
                days.push(
                    <div
                        className={`FD-calendar-cell ${
                            !isSameMonth(day, monthStart) ? 'disabled' : ''
                        } ${isSameDay(day, selectedDate) ? 'selected' : ''}`}
                        key={day}
                        onClick={() => onDateClick(
                            new Date(cloneDay.getFullYear(), cloneDay.getMonth(), cloneDay.getDate()),
                            tasksForDay
                        )}
                    >
                        <span className="FD-calendar-number">{formattedDate}</span>
    
                        {/* Render dots conditionally */}
                        <div className="FD-calendar-task-dot-container">
                            {hasBilledTime && (
                                <div className='FD-calendar-task-dot billedTime-dot'></div>
                            )}
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(
                <div className="FD-calendar-row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
    
        return <div className="FD-calendar-body">{rows}</div>;
    };
    
        
    const onDateClick = (day, tasksForDay) => {
        setSelectedDate(day);
        if (tasksForDay.length > 0) {
            setPopupTasks(tasksForDay);
            setIsPopupVisible(true);
        } else {
            setIsPopupVisible(false);
        }
    };
    

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const closePopup = () => {
        setIsPopupVisible(false);
        setPopupTasks([]);
    };

    return (
        <div className="FD-calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {isPopupVisible && (
                <div className="FD-calendar-popup">
                    <div className="FD-calendar-popup-content" ref={popupRef}>
                        <h4>Events for {format(selectedDate, 'MMMM d, yyyy')}</h4>
                        <ul>
                            {popupTasks.map((task, index) => {
                                const itemClass = task.type === 'checklist' 
                                    ? 'checklist-item' 
                                    : task.type === 'deadline' 
                                        ? 'deadline-item' 
                                        : task.type === 'task' 
                                        ? 'task-item'
                                        : 'billedTime-item';
                                
                                return (
                                    <li key={index} className={itemClass}>
                                        <div>
                                            {task.type === 'checklist' ? (
                                                <strong>CHECKLIST</strong>
                                            ) : task.type === 'deadline' ? (
                                                <strong>DEADLINE</strong>
                                            ) : task.type === 'task' ? (
                                                <strong>TASK</strong>
                                            ) : (
                                                <strong>BILLED TIME</strong>
                                            )}
                                            <span className="Calander-task-status">{task.status}</span>
                                        </div>
                                        <div>{task.description || task.task_title}</div>
                                        {task.amount ? <div>Amount: ${task.amount}</div> : <></>}
                                        {task.duedate && (
                                            <span className="task-due-date">
                                                {task.type === 'billed_time' ? 
                                                    `Created: ${formatLocalDate(task.duedate)}` : 
                                                    `Due: ${formatLocalDate(task.duedate)}`
                                                }
                                            </span>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Calendar;
