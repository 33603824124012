import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ClientMatters.css';
import getAuthHeaders from '../../../AppSettings/routes/functions/getAuthHeaders';
import ClientService from '../../../AppSettings/services/ClientService';

const ClientMatters = () => {
    const { clientId } = useParams();
    const [matters, setMatters] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [deadlines, setDeadlines] = useState([]);
    const [selectedTab, setSelectedTab] = useState('matters');
    const [mattersCount, setMattersCount] = useState(0);
    const [tasksCount, setTasksCount] = useState(0);
    const [deadlinesCount, setDeadlinesCount] = useState(0);

    const fetchClientMatters = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/clientmatters/${clientId}`, {
                headers: await getAuthHeaders(),
            });
            if (response.ok) {
                const data = await response.json();
                const sortedData = data.sort((a, b) => {
                    const numA = parseInt(a.matterid.split('-').pop(), 10);
                    const numB = parseInt(b.matterid.split('-').pop(), 10);
                    return numA - numB;
                });
                setMatters(sortedData);
            } else {
                throw new Error('Failed to fetch client matters');
            }
        } catch (error) {
            console.log('Error fetching client matters: ' + error);
        }
    };

    const fetchTasks = async () => {
        try {
            const tasksData = await ClientService.getTasksByClient(clientId);
            if (tasksData) {
                setTasks(tasksData);
            }
        } catch (error) {
            console.log('Error fetching tasks:', error);
        }
    };

    const fetchDeadlines = async () => {
        try {
            const deadlinesData = await ClientService.getDeadlinesByClient(clientId);
            if (deadlinesData) {
                setDeadlines(deadlinesData);
            }
        } catch (error) {
            console.log('Error fetching deadlines:', error);
        }
    };

    useEffect(() => {
        fetchClientMatters();
    }, [clientId]);

    useEffect(() => {
        async function fetchMattersCount() {
            try {
                const count = await ClientService.getClientMattersCount(clientId);
                setMattersCount(count);
            } catch (error) {
                console.error('Error fetching client matters count:', error);
            }
        }

        fetchMattersCount();
    }, [clientId]);

    useEffect(() => {
        async function fetchTasksCount() {
            try {
                const count = await ClientService.getTasksCountByClient(clientId);
                setTasksCount(count);
            } catch (error) {
                console.error('Error fetching tasks count:', error);
            }
        }

        fetchTasksCount();
    }, [clientId]);

    useEffect(() => {
        async function fetchDeadlinesCount() {
            try {
                const count = await ClientService.getDeadlinesCountByClient(clientId);
                setDeadlinesCount(count);
            } catch (error) {
                console.error('Error fetching deadlines count:', error);
            }
        }

        fetchDeadlinesCount();
    }, [clientId]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        if (tab === 'tasks') {
            fetchTasks();
        } else if (tab === 'deadlines') {
            fetchDeadlines();
        }
    };

    return (
        <div>
          <div className="Client-Details-banner">
              <div
                className={`Client-Details-banner-item ${selectedTab === 'matters' ? 'selected' : ''}`}
                onClick={() => handleTabClick('matters')}
              >
                  Matters <span>{mattersCount}</span>
              </div>
              <div
                className={`Client-Details-banner-item ${selectedTab === 'tasks' ? 'selected' : ''}`}
                onClick={() => handleTabClick('tasks')}
              >
                  Task <span>{tasksCount}</span>
              </div>
              <div
                className={`Client-Details-banner-item ${selectedTab === 'deadlines' ? 'selected' : ''}`}
                onClick={() => handleTabClick('deadlines')}
              >
                  Deadlines <span>{deadlinesCount}</span>
              </div>
          </div>
      
          <div className="table-container">
            {selectedTab === 'matters' && (
              <table className="table">
                  <thead>
                      <tr>
                          <th>Matter</th>
                          <th>Leader</th>
                          <th>Status</th>
                          <th>Open Date</th>
                          <th>Close Date</th>
                      </tr>
                  </thead>
                  <tbody>
                      {matters.map((matter) => (
                          <tr key={matter.matterid}>
                              <td>
                                  <Link to={`/matterDetails/${matter.matterid}`} className="ClientLink">
                                      {matter.matterid} {matter.mattername}
                                  </Link>
                              </td>
                              <td>{matter.employeename}</td>
                              <td>{matter.status}</td>
                              <td>{matter.opendate}</td>
                              <td>{matter.closedate}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
            )}
            {selectedTab === 'tasks' && (
              <table className="table">
                  <thead>
                      <tr>
                        <th>Task</th>
                        <th>Matter</th>   
                        <th>Status</th>
                        <th>Type</th>
                        <th>Assigned To</th>
                        <th>Due Date</th>
                      </tr>
                  </thead>
                  <tbody>
                      {tasks.map((task) => (
                          <tr key={task.taskid}>
                              <td>{task.task_title}</td>
                              <td>{task.matterid}</td>
                              <td>{task.status}</td>
                              <td>{task.task_type}</td>
                              <td>{task.assignedto}</td>
                              <td>{task.duedate}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
            )}
            {selectedTab === 'deadlines' && (
              <table className="table">
                  <thead>
                      <tr>
                        <th>Deadline ID</th>
                        <th>Type</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Internal/External</th>
                      </tr>
                  </thead>
                  <tbody>
                      {deadlines.map((deadline) => (
                          <tr key={deadline.deadline_id}>
                              <td>{deadline.deadline_id}</td>
                              <td>{deadline.type}</td>
                              <td>{deadline.due_date}</td>
                              <td>{deadline.status}</td>
                              <td>{deadline.internal_external}</td>
                          </tr>
                      ))}
                  </tbody>
              </table>
            )}
          </div>
        </div>
    );
};

export default ClientMatters;
