import React, { useState, useEffect } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import {
    format,
    addMonths,
    subMonths,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    addDays,
    isSameMonth,
    isSameDay
} from 'date-fns';
import styles from './BillableCalender.css';
import EmployeeService from '../../../AppSettings/services/EmployeeService';

const BillableCalender = ({ data }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [employee, setEmployee] = useState([]);
    
    useEffect(() => {
        const dayEvents = data.filter(event => event.date && new Date(event.date).toDateString() === selectedDate.toDateString());
        
        if (dayEvents.length > 0) {
            const employeeId = dayEvents[0].employeeid;
            if (employeeId) {
                getEmployeeById(employeeId); // Fetch employee details if there are events for that day
            }
        }
    }, [selectedDate, data]); // Re-run this effect when selectedDate or data changes

    const renderHeader = () => (
        <div className={styles.BillableCalendarHeader}>
            <button className={styles.BillableCalendarHeaderButton} onClick={prevMonth}>
                <FaChevronLeft />
            </button>
            <span className={styles.BillableCalendarHeaderTitle}>
                {format(currentMonth, 'MMMM yyyy')}
            </span>
            <button className={styles.BillableCalendarHeaderButton} onClick={nextMonth}>
                <FaChevronRight />
            </button>
        </div>
    );


    const renderDays = () => {
        const days = [];
        const dateFormat = 'EEE';
        let startDate = startOfWeek(currentMonth);

        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="Billable-calendar-day-name" key={i}>
                    {format(addDays(startDate, i), dateFormat)}
                </div>
            );
        }

        return <div className="Billable-calendar-days-row">{days}</div>;
    };

    const renderCells = () => {
        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
    
        const rows = [];
        let days = [];
        let day = startDate;
        const dateFormat = 'd';
    
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const formattedDate = format(day, dateFormat);
                const cloneDay = day;
                const dayEvents = data.filter(event => event.date && new Date(event.date).toDateString() === day.toDateString());
    
                let totalAmount = 0;
                let formattedTotalTime = '';
                let employeeId = null; // Default employeeId
    
                if (dayEvents.length > 0) {
                    let totalSeconds = dayEvents.reduce((total, event) => {
                        const [hours, minutes, seconds] = event.time_recorded.split(':').map(Number);
                        return total + hours * 3600 + minutes * 60 + seconds;
                    }, 0);
    
                    const totalHours = Math.floor(totalSeconds / 3600);
                    const totalMinutes = Math.floor((totalSeconds % 3600) / 60);
    
                    if (totalHours > 0) {
                        formattedTotalTime += `${totalHours}hr${totalHours > 1 ? 's' : ''} `;
                    }
                    if (totalMinutes > 0) {
                        formattedTotalTime += `${totalMinutes}m${totalMinutes > 1 ? 's' : ''}`;
                    }
    
                    totalAmount = dayEvents.reduce((sum, event) => sum + parseFloat(event.amount || 0), 0);
    
                    // Assuming the employeeId is present in one of the events (you may need to adjust based on your data)
                    employeeId = dayEvents[0].employeeid; 
                }
    
                days.push(
                    <div
                        className={`${styles.BillableCalendarCell} ${!isSameMonth(day, monthStart) ? styles.Disabled : ''} ${isSameDay(day, selectedDate) ? styles.Selected : ''}`}
                        key={day}
                        onClick={() => {
                            setSelectedDate(cloneDay);
                            setEmployee("");
                            if (employeeId) {
                                getEmployeeById(employeeId); // Fetch employee by employeeid
                            }
                        }}
                    >
                        <span className={styles.BillableCalendarNumber}>{formattedDate}</span>
                        <div className={styles.BillableCalendarTileContent}>
                            {/* Only show the dot if the day is within the current month and has events */}
                            {isSameMonth(day, currentMonth) && dayEvents.length > 0 && (
                                <div className={styles.BillableCalendarDot}></div>
                            )}
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }
            rows.push(<div className={styles.BillableCalendarRow} key={day}>{days}</div>);
            days = [];
        }
    
        return <div className={styles.BillableCalendarBody}>{rows}</div>;
    };

    const nextMonth = () => {
        setCurrentMonth(addMonths(currentMonth, 1));
    };

    const prevMonth = () => {
        setCurrentMonth(subMonths(currentMonth, 1));
    };

    const getEmployeeById = async (employeeid) => {
        try {
            const employee = await EmployeeService.getEmployeeById(employeeid);
            setEmployee(employee);
        } catch (error) {
            console.error("Error fetching employee:", error);
        }
    };


    return (
        <div className={styles.BillableCalendarContainer}>
            <div className={styles.BillableCalendar}>
                {renderHeader()}
                {renderDays()}
                {renderCells()}
            </div>
            <div className={styles.BillableSidebar}>
                <h2>Events on {format(selectedDate, 'MMMM d, yyyy')}</h2>
                {data.filter(event => new Date(event.date).toDateString() === selectedDate.toDateString()).length > 0 ? (
                    data.filter(event => new Date(event.date).toDateString() === selectedDate.toDateString()).map((event, index) => (
                        <div key={index} className={styles.BillableSidebarEvent}>
                            <h3>{event.notes || 'Billable Time Entry'}</h3>
                            <p>Time Recorded: {event.time_recorded}</p>
                            <p>Amount: ${isNaN(event.amount) ? 0 : event.amount}</p>
                            {employee ?
                            <div className={styles.EmployeeDetails}>
                                <h3>Employee: {employee.firstname} {employee.lastname}</h3>
                            </div> : <></>
                            }
                        </div>
                    ))
                ) : (
                    <p>No events for this day</p>
                )}
            </div>
        </div>
    );
};

export default BillableCalender;
