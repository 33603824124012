import React, { useState, useEffect } from 'react';
import WorkflowService from '../../../AppSettings/services/WorkflowService';
import CreateWorkflow from './CreateWorkflow/CreateWorkflow';
import './ProfileWorkflow.css';

const ProfileWorkflow = () => {
    const [workflows, setWorkflows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Fetch workflows when the component mounts
    useEffect(() => {
        const fetchWorkflows = async () => {
            try {
                const fetchedWorkflows = await WorkflowService.getWorkflows();
                const sanitizedWorkflows = fetchedWorkflows.map((workflow) => ({
                    ...workflow,
                    milestones: workflow.milestones || [],
                }));
                setWorkflows(sanitizedWorkflows);
            } catch (error) {
                console.error('Error fetching workflows:', error);
            }
        };

        fetchWorkflows();
    }, []);

    // Add a new workflow
    const handleAddWorkflow = async (workflow) => {
        try {
            const addedWorkflow = await WorkflowService.addWorkflow(workflow); // Send milestones array
            setWorkflows([...workflows, { ...addedWorkflow, milestones: addedWorkflow.milestones || [] }]);
            setIsModalOpen(false); // Close modal
        } catch (error) {
            console.error('Error adding workflow:', error);
        }
    };

    // Render workflows
    const renderWorkflows = () => {
        if (workflows.length === 0) {
            return <p>No workflows available. Create a new workflow to get started.</p>;
        }

        return (
            <ul className="workflow-list">
                {workflows.map((workflow) => (
                    <li key={workflow.workflowid} className="workflow-item">
                        <strong>{workflow.name}</strong>
                        <p>Milestones: {workflow.milestones?.join(', ') || 'None'}</p>
                    </li>
                ))}
            </ul>
        );
    };

    return (
<div className="profile-workflow-container">
  <div className="workflow-header">
    <h2 className="workflow-title">Workflow</h2>
    <button onClick={() => setIsModalOpen(true)} className="open-modal-button">
      +
    </button>
  </div>

  {renderWorkflows()}

  {isModalOpen && (
    <CreateWorkflow
      onAddWorkflow={handleAddWorkflow}
      onClose={() => setIsModalOpen(false)}
    />
  )}
</div>
    );
};

export default ProfileWorkflow;
