import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import TopBar from "./Components/TopBar/TopBar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faUsers,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ClientService from "../../AppSettings/services/ClientService";
import EmployeeService from "../../AppSettings/services/EmployeeService";
import WorkflowService from "../../AppSettings/services/WorkflowService";
import "./CreateMattersPage.css";

const CreateMattersPage = () => {
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [showWorkflows, setShowWorkflows] = useState(false);
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false); 
  const [loadingWorkflows, setLoadingWorkflows] = useState(false);
  const [error, setError] = useState("");
  const [showEmployeeModal, setShowEmployeeModal] = useState(false);
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);

  const clientData = useQuery({
    queryKey: ["Clients"],
    queryFn: ClientService.getClients,
  });

  const employeeData = useQuery({
    queryKey: ["Employee"],
    queryFn: EmployeeService.getEmployees,
  });

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      assoc_employees: selectedTeamMembers,
    }));
  }, [selectedTeamMembers]);
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientData, employeeData] = await Promise.all([
          ClientService.getClients(),
          EmployeeService.getEmployees(),
        ]);

        setClients(
          clientData.map((client) => ({
            value: client.ClientID,
            label: `${client.ClientID} - ${client.FirstName} (${client.Company})`,
          }))
        );

        setEmployees(
          employeeData.map((employee) => ({
            value: employee.EmployeeID,
            label: `${employee.FirstName} ${employee.LastName}`,
          }))
        );
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load clients or employees.");
      }
    };

    fetchData();
  }, []);
  
  const fetchWorkflows = async () => {
    try {
      setLoadingWorkflows(true);
      const workflowsData = await WorkflowService.getWorkflows();
      setWorkflows(workflowsData);
    } catch (err) {
      console.error("Error fetching workflows:", err);
      setError("Failed to load workflows.");
    } finally {
      setLoadingWorkflows(false);
    }
  };

  const toggleModals = async () => {
    if (!showWorkflows || !showMoreInfoModal) {
      await fetchWorkflows(); // Only fetch workflows when opening
    }
    setShowWorkflows((prev) => !prev);
    setShowMoreInfoModal((prev) => !prev);
  };

  // State for form inputs
  const [formData, setFormData] = useState({
    mattername: "",
    mattertype: "",
    clientid: "",
    employeeid: "",
    opendate: "",
    closedate: "",
    description: "",
    amount: "0",
    workflowid: "", // Added workflow ID state
    country: "",
    application_date: "",
    application_no: "",
    registration_date: "",
    registration_no: "",
    publication_date: "",
    publication_no: "",
    application_status: "",
    priority_data: "",
    office_action_date: "",
    expiry_date: "",
  });


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (field) => (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleWorkflowClick = (workflowId) => {
    setFormData((prevData) => ({
      ...prevData,
      workflowid: workflowId,
    }));
  };

  const handleAddTeamMembersClick = () => {
    setShowEmployeeModal((prev) => !prev);
  };

  return (
    <div className="mattersPage">
      <TopBar formData={formData} />
      <div className="Matter-entire-form-container">
        <div className="Matter-form-container">
          {/* Matter Name */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Matter Name</label>
            <div className="Matter-input-icon">
              <input
                type="text"
                name="mattername"
                placeholder="Type in name"
                value={formData.mattername}
                onChange={handleInputChange}
              />
            </div>
          </div>
         
            <div className="Matter-form-group">
              <label className="Matter-form-group-label">Matter Type</label>
              <div >
                <Select
                  options={[
                    { value: "Trademark", label: "Trademark" },
                    { value: "Patent", label: "Patent" },
                    { value: "Strategy", label: "Strategy" },
                    { value: "Design", label: "Design" },
                    { value: "Copyright", label: "Copyright" },
                    { value: "Commercial", label: "Commercial" },
                    { value: "Cautionary_Notice", label: "Cautionary Notice" },
                    { value: "Utility_Model", label: "Utility Model" },
                  ]}
                  placeholder="Select or search a matter type..."
                  onChange={(selectedOption) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      mattertype: selectedOption ? selectedOption.value : "",
                    }))
                  }
                  value={
                    formData.mattertype
                      ? { value: formData.mattertype, label: formData.mattertype }
                      : null
                  }
                  isClearable
                />
              </div>
            </div>

          {/* Client Dropdown */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Client</label>
            <Select
              options={clients}
              onChange={handleSelectChange("clientid")}
              placeholder="Select or search a client..."
              isClearable
              isLoading={clientData.isLoading}
            />
          </div>

          {/* Assign Leader Dropdown */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Assign Leader</label>
            <Select
              options={employees}
              onChange={handleSelectChange("employeeid")}
              placeholder="Select or search an employee..."
              isClearable
            />
          </div>

          {/* Date Inputs */}
          <div className="Matter-date-group">
            <div className="Matter-item1">
              <label className="Matter-form-group-label">Creation Date</label>
              <div className="Matter-input-icon">
                <input
                  type="date"
                  name="opendate"
                  value={formData.opendate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="Matter-item2">
              <label className="Matter-form-group-label">Due Date</label>
              <div className="Matter-input-icon">
                <input
                  type="date"
                  name="closedate"
                  value={formData.closedate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="Matter-form-group">
            <label className="Matter-form-group-label">Description</label>
            <textarea
              className="matter-textarea"
              name="description"
              placeholder="Type"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Side Buttons */}
        <div className="Matter-side-buttons">
          <button className="Matter-side-button side1" onClick={handleAddTeamMembersClick}>
            Add Team Members <FontAwesomeIcon icon={faUsers} />
          </button>
          <button className="Matter-side-button side2">
            Add Invoices <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="Matter-side-button side3" onClick={toggleModals}>
            More Information <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button className="Matter-side-button side4">
            Add Financial Data <FontAwesomeIcon icon={faPlus} />
          </button>
          <button className="Matter-side-button side5">
            Create Sub Matter <FontAwesomeIcon icon={faChevronDown} />
          </button>
          <button className="Matter-side-button side6">
            Add Documents <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>

      {(showMoreInfoModal || showWorkflows ) && (
  <div className="Create-matters-overlay" onClick={toggleModals}>
  
    {showMoreInfoModal && (
      <div
        className="AD-modal-form"
        onClick={(e) => e.stopPropagation()} /* Prevent closing on modal click */
      >
        <h3>Add Additional Information</h3>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Country</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Application Date</label>
          <div className="Matter-input-icon">
            <input
              type="date"
              name="application_date"
              value={formData.application_date}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Application No</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="application_no"
              value={formData.application_no}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Registration Date</label>
          <div className="Matter-input-icon">
            <input
              type="date"
              name="registration_date"
              value={formData.registration_date}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Registration No</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="registration_no"
              value={formData.registration_no}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Publication Date</label>
          <div className="Matter-input-icon">
            <input
              type="date"
              name="publication_date"
              value={formData.publication_date}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Publication No</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="publication_no"
              value={formData.publication_no}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Application Status</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="application_status"
              value={formData.application_status}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Priority Data</label>
          <div className="Matter-input-icon">
            <input
              type="text"
              name="priority_data"
              value={formData.priority_data}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Office Action Date</label>
          <div className="Matter-input-icon">
            <input
              type="date"
              name="office_action_date"
              value={formData.office_action_date}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="Matter-form-group">
          <label className="Matter-form-group-label">Expiry Date</label>
          <div className="Matter-input-icon">
            <input
              type="date"
              name="expiry_date"
              value={formData.expiry_date}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    )}
      {showWorkflows && (
      <div
        className="Create-matters-workflows-list"
        onClick={(e) => e.stopPropagation()} /* Prevent closing on list click */
      >
        <h3>Available Workflows</h3>
        {loadingWorkflows ? (
          <p>Loading workflows...</p>
        ) : workflows.length > 0 ? (
          <ul className="Create-matters-workflow-list">
            {workflows.map((workflow) => (
              <li
                key={workflow.workflowid}
                onClick={() => handleWorkflowClick(workflow.workflowid)}
                className={`Create-matters-workflow-item ${
                  formData.workflowid === workflow.workflowid ? "selected-workflow" : ""
                }`}
              >
                <strong>{workflow.name}</strong>
                <p>Milestones: {workflow.milestones?.join(", ") || "None"}</p>
              </li>
            ))}
          </ul>
        ) : (
          <p>No workflows available.</p>
        )}
      </div>
    )}
  </div>
)}


              {showEmployeeModal && (
                <div className="Create-matters-overlay" onClick={handleAddTeamMembersClick}>
                  <div
                    className="CM-addTM-popout"
                    onClick={(e) => e.stopPropagation()} // Prevent closing on modal click
                  >
                    <h3>Select Team Members</h3>
                    <Select
                      isMulti
                      options={employees} // Use the employees list for options
                      onChange={(selectedOptions) => {
                        setSelectedTeamMembers(selectedOptions.map((option) => option.value));
                      }}
                      value={employees.filter((emp) => selectedTeamMembers.includes(emp.value))}
                      placeholder="Select team members..."
                    />
                  </div>
                </div>
              )}
          </div>

);

};

export default CreateMattersPage;
