import React, { useState, useRef, useEffect } from "react";
import styles from "./BillableTimeForm.module.css";

const BillableTimeForm = ({ onSubmit, employee, setShowAddForm, matterId }) => {
  const [timeRecorded, setTimeRecorded] = useState("");
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");

  const modalRef = useRef(null); // Modal reference to check if the click was outside the modal

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAddForm(false); // Close modal
      }
    };

    // Bind the click event
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowAddForm]);

  // Format time to HH:MM:SS
  const formatTimeRecorded = (time) => {
    const totalMinutes = parseFloat(time) * 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }:00`;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if employee data is available
    if (!employee || !employee.billedrate) {
      console.error("Employee data or billed rate is not available.");
      setError("Employee data is missing.");
      return;
    }

    const formattedTime = formatTimeRecorded(timeRecorded);
    const billedRate = employee.billedrate;

    // Check if timeRecorded is valid
    if (!formattedTime) {
      console.error("Invalid time recorded.");
      setError("Invalid input data.");
      return;
    }

    // Check for no billed rate
    if (isNaN(billedRate)) {
      console.error("Invalid billed rate.");
      setError(
        "You currently have no billed rate. Go to your profile to enter a billed rate."
      );
      return;
    }

    // Calculate the amount
    const amount = (
      billedRate *
      (parseFloat(formattedTime.split(":")[0]) +
        parseFloat(formattedTime.split(":")[1]) / 60)
    ).toFixed(2);

    const formData = {
      timeRecorded: formattedTime,
      date,
      notes,
      amount,
      employeeid: employee.employeeid,
      billedrate: billedRate,
      matterId,
    };

    onSubmit(formData); // Send the form data to the parent
    setShowAddForm(false); // Close the form after submission
  };

  return (
    <div className={styles["Fixed-fees-overlay"]}>
      <div className={styles["Fixed-fees-modal"]} ref={modalRef}>
        <div className={styles["title-button-container"]}>
          <h2>Add Billable Time</h2>
        </div>
        {error && <p className="error-message">{error}</p>}
        <form className={styles["new-fee-form"]} onSubmit={handleSubmit}>
          <input
            type="number"
            className="input-field"
            placeholder="Enter time recorded (in hours)"
            value={timeRecorded}
            onChange={(e) => setTimeRecorded(e.target.value)}
            required
          />
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <textarea
            className={styles["billable-time-textarea"]}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Type notes here"
            name="notes"
          />
          <button type="submit" className={styles["submit-fee-button"]}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default BillableTimeForm;
