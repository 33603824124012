import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css';
import { useState } from "react";

const columns = ["Subject", "Description"];

function SearchBar({ onSearch }) {
  const [selectedColumn, setSelectedColumn] = useState("Search by:");
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    onSearch(value, selectedColumn === "Search by:" ? null : selectedColumn); // Pass the search value back to the parent
  };

    // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  // Handle column selection
  const handleColumnSelect = (column) => {
    setSelectedColumn(column);
    setIsDropdownOpen(false);

    // Trigger search with the current search term and the new selected column
    onSearch(searchTerm, column === "Search by:" ? null : column);
  };

  return (
    <div className="search-bar-matters">
      <div className="input-wrapper">
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          onChange={handleInputChange}
        />
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
      </div>
      <div className="filter-button-matters">
        <button
          onClick={toggleDropdown}
          className="filter-button-matters-dropdown"
        >
        <FontAwesomeIcon icon={faBarsStaggered} className="filter-icon" />  {selectedColumn}
        </button>
        {isDropdownOpen && (
          <ul className="filter-matters-dropdown-menu">
            {columns.map((column) => (
              <li
                key={column}
                onClick={() => handleColumnSelect(column)}
                className="filter-matters-dropdown-item"
              >
                {column}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
