import React, { useState, useEffect } from 'react';
import './AdditionalNotes.css';
import getAuthHeaders from '../../../AppSettings/routes/functions/getAuthHeaders';
import { useParams } from 'react-router-dom';
import {FaPlus } from 'react-icons/fa';
import TopBar from './Components/TopBar';
const AdditionalNotes = () => {
    const { matterId } = useParams();
    const [notes, setNotes] = useState([]);
    const [newNote, setNewNote] = useState('');
    const [showForm, setShowForm] = useState(false);
    const fetchNotes = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notes/${matterId}`, {
              headers: await getAuthHeaders()
            });
            const data = await response.json();
            setNotes(data);
        } catch (error) {
            console.error('Error fetching notes:', error);
        }
    };
    const [selectedNote, setSelectedNote] = useState(null);
    const togglePopOut = (note) => {
      setSelectedNote(note);
  };
  
    const [expandedNoteId, setExpandedNoteId] = useState(null);
    const toggleNoteExpansion = (noteId) => {
      if (expandedNoteId === noteId) {
          setExpandedNoteId(null); // Collapse the note if it's already expanded
      } else {
          setExpandedNoteId(noteId); // Expand the new note
      }
  };
  
    const handleNoteChange = (event) => {
        setNewNote(event.target.value);
    }

    const handleNoteSubmit = async (event) => {
        event.preventDefault();
    
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/notes/${matterId}`, {
            method: 'POST',
            headers: await getAuthHeaders(),
            body: JSON.stringify({ note_text: newNote }),
          });
    
          if (response.ok) {
            fetchNotes();
            setShowForm(false);
            setNewNote('');
          } else {
            console.error('Failed to submit note');
          }
        } catch (error) {
          console.error('Error submitting note:', error);
        }
      };

    useEffect(() => {
        fetchNotes();
    }, [])

    return (
      <div  className="Tab-Container">
        <TopBar />
        <div className="additional-notes-section">
        <button
          className="noteAdd-Button"
            onClick={() => setShowForm(!showForm)} // This toggles the form's visibility
            style={{  marginTop: '10px', marginBottom: '10px' }}
           >
        +
        </button>

        {showForm && (
        <form onSubmit={handleNoteSubmit} style={{ marginBottom: '20px' }}>
            <label>
                New Note:
                <textarea value={newNote} onChange={handleNoteChange} />
            </label>
            <button type="submit">Post Note</button>
        </form>
        )}
        
     
        <div className="notes-container">
        {notes.map((note) => (
   <div
        key={note.noteid}
        className={`note-item ${expandedNoteId === note.noteid ? 'expanded' : ''}`}
            onClick={() => togglePopOut(note)}
    >
        <p>{note.created_at}</p>
        <p>{note.note_text}</p>
    </div>
            )).reverse()}
            {selectedNote && (
    <div className="SelectedNote-pop-out">
        <div className="SelectedNote-pop-out-content">
            <span className="SelectedNote-close" onClick={() => togglePopOut(null)}>&times;</span>
            <p>{selectedNote.created_at}</p>
            <p>{selectedNote.note_text}</p>
        </div>
    </div>
)}

     
</div>
        </div>
        </div>
    );
};

export default AdditionalNotes;
