import React, { useState } from 'react';
import './CreateWorkflow.css';

const CreateWorkflow = ({ onAddWorkflow, onClose }) => {
    const [newWorkflow, setNewWorkflow] = useState({ name: '', milestones: [] });
    const [errorMessage, setErrorMessage] = useState('');

    // Add a new milestone
    const handleAddMilestone = () => {
        setNewWorkflow((prevState) => ({
            ...prevState,
            milestones: [...prevState.milestones, ''], // Add an empty string for the new milestone
        }));
    };

    // Update milestone value
    const handleMilestoneChange = (index, value) => {
        const updatedMilestones = [...newWorkflow.milestones];
        updatedMilestones[index] = value; // Update the specific milestone with the new value
        setNewWorkflow((prevState) => ({ ...prevState, milestones: updatedMilestones }));
    };

    // Remove a milestone
    const handleDeleteMilestone = (index) => {
        const updatedMilestones = newWorkflow.milestones.filter((_, i) => i !== index);
        setNewWorkflow((prevState) => ({ ...prevState, milestones: updatedMilestones }));
    };

    // Handle form submission
    const handleAddWorkflow = () => {
        if (!newWorkflow.name.trim()) {
            setErrorMessage('Workflow name is required.');
            return;
        }
        if (newWorkflow.milestones.length === 0 || newWorkflow.milestones.some((m) => !m.trim())) {
            setErrorMessage('Please add valid milestone names.');
            return;
        }

        // Pass the workflow name and milestone values to the parent
        onAddWorkflow({ name: newWorkflow.name, milestones: newWorkflow.milestones });
        setNewWorkflow({ name: '', milestones: [] }); // Reset input fields
        setErrorMessage(''); // Clear any error messages
    };

    return (
        <div className="CreateWorkflow-reporting-overlay" onClick={(e) => e.target.className === 'CreateWorkflow-reporting-overlay' && onClose()}>
            <div className="CreateWorkflow-reporting-modal-content">
                <h2>Create New Workflow</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <input
                    type="text"
                    placeholder="Workflow Name"
                    value={newWorkflow.name}
                    onChange={(e) =>
                        setNewWorkflow((prevState) => ({ ...prevState, name: e.target.value }))
                    }
                    className="CreateWorkflow-workflow-input"
                />
                <div className="CreateWorkflow-milestones-header">
                    <h3>Milestones</h3>
                    <button onClick={handleAddMilestone} className="CreateWorkflow-add-milestone-button">
                        +
                    </button>
                </div>
                <div className="CreateWorkflow-milestones-container">
                    {newWorkflow.milestones.map((milestone, index) => (
                        <div key={index} className="CreateWorkflow-milestone-item">
                            <input
                                type="text"
                                placeholder={`Milestone ${index + 1}`}
                                value={milestone}
                                onChange={(e) =>
                                    handleMilestoneChange(index, e.target.value)
                                }
                                className="CreateWorkflow-milestone-input"
                            />
                            <button
                                onClick={() => handleDeleteMilestone(index)}
                                className="CreateWorkflow-delete-milestone-button"
                            >
                                -
                            </button>
                        </div>
                    ))}
                </div>
                <div className="CreateWorkflow-modal-actions">
                    <button onClick={handleAddWorkflow} className="CreateWorkflow-add-workflow-button">
                        Add 
                    </button>

                </div>
            </div>
        </div>
    );
};

export default CreateWorkflow;
