import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class RulesService {
    
    // Fetch all deadline rules
    static async getDeadlineRules() {
        try {
            const response = await axios.get(`${API_URL}/deadline-rules`, {
                headers: await getAuthHeaders(),
            });
            console.log('Deadline Rules:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching deadline rules:', error);
            throw new Error('An error occurred while fetching deadline rules.');
        }
    }

    // Delete a specific deadline rule by ID
    static async deleteDeadlineRule(ruleId) {
        try {
            const response = await axios.delete(`${API_URL}/delete_deadline_rule/${ruleId}`, {
                headers: await getAuthHeaders(),
            });
            console.log('Delete Rule Response:', response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting deadline rule with ID ${ruleId}:`, error);
            throw new Error('An error occurred while deleting the deadline rule.');
        }
    }
}

export default RulesService;
