import React, { useState } from "react";
import GenerateInvoice from "./Components/GenerateInvoice";
import InvoiceView from "./Components/InvoiceView";
import "./InvoicePage.css";
import { useParams } from "react-router-dom";

const InvoicePage = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const { matterId } = useParams();
  // const handleGenerateInvoice = async () => {
  //     // Call the generateInvoicePDF function inside GenerateInvoice and get the PDF URL
  //     const { pdfUrl } = await GenerateInvoice({ matterId }); // Pass matterId to GenerateInvoice
  //     setPdfUrl(pdfUrl); // Set the generated PDF URL
  // };

  return (
    <div>
      {/* <button onClick={handleGenerateInvoice}>Generate Invoice PDF</button> */}
      <GenerateInvoice matterId={matterId} />
    </div>
  );
};

export default InvoicePage;
