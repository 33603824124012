import React, { useState, useRef } from "react";
import IconButton from "./Components/IconButton";
import "./TopBar.css";
import { faUser, faUserFriends, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import SearchBar from "./Components/SearchBar";
import ExportTasks from "./Components/ExportTasks";

const TopBar = ({ onSearch, onToggleTasks, activeTable, columns }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [currentTasks, setCurrentTasks] = useState(true);  // State to track current tasks, false is All Tasks, true is My Tasks
  const modalContentRef = useRef(null);

  const handleUploadClick = () => {
    setShowExportModal(true);
  };

  const closeModal = () => {
    setShowExportModal(false);
  };

  const handleOverlayClick = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleToggle = (bool) => {
    setCurrentTasks(bool);  // Update local state
    onToggleTasks(bool);    // Call the external function
  };

  return (
    <div className="topBar">
      <div id="leftSide">
        {activeTable === "Tasks" ? 
        <>
          <IconButton
            icon={faUser}
            ariaLabel="User"
            onClick={() => handleToggle(true)} // Show "My Tasks"
            style={{ backgroundColor: currentTasks ? "#FFFFFF" : "", border: currentTasks ? "1px solid black" : "" }}
          />
          <IconButton
            icon={faUserFriends}
            ariaLabel="Group"
            onClick={() => handleToggle(false)} // Show "All Tasks"
            style={{ backgroundColor: currentTasks ? "" : "#FFFFFF", border: currentTasks ? "" : "1px solid black" }}
          />
        </> 
        : <></>}

      </div>
      <div id="rightSide">
      <IconButton icon={faUpload} ariaLabel="Upload" onClick={handleUploadClick} />
      <SearchBar onSearch={onSearch} columns={columns} />
      </div>
      {showExportModal && (
        <div className="modal" onClick={handleOverlayClick}>
          <div className="modal-content" ref={modalContentRef}>
            <ExportTasks />
          </div>
        </div>
      )}

    </div>
  );
};

export default TopBar;
