import React, { useState, useRef } from "react";
import IconButton from "./Components/IconButton";
import "./TopBar.css";
import { faUser, faUserFriends, faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import SearchBar from "./Components/SearchBar";
import ExportMatters from "./Components/ExportMatters";


const TopBar = ({ onSearch, onToggleMatters, currMatterState = true }) => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [currentMatters, setCurrentMatters] = useState(currMatterState);  // State to track current tasks, false is All, true is My
  const modalContentRef = useRef(null);

  const handleUploadClick = () => {
    setShowExportModal(true);
  };

  const closeModal = () => {
    setShowExportModal(false);
  };

  const handleOverlayClick = (event) => {
    if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const handleToggle = (bool) => {
    setCurrentMatters(bool);  // Update local state
    onToggleMatters(bool);    // Call the external function
  };

  return (
    <div className="topBar">
      <div id="leftSide">
        <IconButton
          icon={faUser}
          ariaLabel="User"
          onClick={() => handleToggle(true)}
          style={{ backgroundColor: currentMatters ? "#FFFFFF" : "", border: currentMatters ? "1px solid black" : "" }}
        />
        <IconButton
          icon={faUserFriends}
          ariaLabel="Group"
          onClick={() => handleToggle(false)}
          style={{ backgroundColor: currentMatters ? "" : "#FFFFFF", border: currentMatters ? "" : "1px solid black" }}
        />
        <Link to="/createMatters">
          <IconButton icon={faPlus} ariaLabel="Add" />
        </Link>
      </div>
      <div id="rightSide">
        <IconButton icon={faUpload} ariaLabel="Upload" onClick={handleUploadClick} />
        <SearchBar onSearch={onSearch} />
      </div>

      {showExportModal && (
        <div className="modal" onClick={handleOverlayClick}>
          <div className="modal-content" ref={modalContentRef}>
            <ExportMatters />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
