import getAuthHeaders from '../routes/functions/getAuthHeaders';
const API_URL = process.env.REACT_APP_API_BASE_URL;

class TaskService {
    
    // Get task status distribution
    static async getTaskStatusDistribution() {
        try {
            const response = await fetch(`${API_URL}/task_status_distribution`, {
                headers: await getAuthHeaders()
            });
            const data = await response.json();
            return data;  // Return the status distribution data
        } catch (error) {
            console.log('Error fetching task status distribution:', error);
            return { error: 'Failed to fetch task status distribution' };
        }
    }

    static async getTaskStatusDistributionByMatterId(matterId) {
        try {
            const response = await fetch(`${API_URL}/task_status_distribution/${matterId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error fetching task status distribution for matter ID: ${matterId}`);
            }
            const data = await response.json();
            return data;  // Return the matter-specific status distribution data
        } catch (error) {
            console.log(`Error fetching task status distribution for matter ID ${matterId}:`, error);
            return { error: `Failed to fetch task status distribution for matter ID: ${matterId}` };
        }
    }
    

    // Get tasks assigned to a specific employee
    static async getTasksByEmployeeId(employeeId) {
        try {
            const response = await fetch(`${API_URL}/mytasks/${employeeId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error fetching tasks for employee ID: ${employeeId}`);
            }
            const data = await response.json();
            return data;  // Return the employee tasks
        } catch (error) {
            console.log(`Error fetching tasks for employee ID ${employeeId}:`, error);
            return { error: `Failed to fetch tasks for employee ID: ${employeeId}` };
        }
        
    }

    // Get all tasks from the database
    static async getAllTasks() {
        try {
            const response = await fetch(`${API_URL}/alltasks`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error('Error fetching all tasks');
            }
            const data = await response.json();
            return data;  // Return all tasks
        } catch (error) {
            console.log('Error fetching all tasks:', error);
            return { error: 'Failed to fetch all tasks' };
        }
    }

    static async addTask(taskData) {
        try {
            const response = await fetch(`${API_URL}/addtask`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...await getAuthHeaders(),
                },
                body: JSON.stringify(taskData),
            });
            if (!response.ok) {
                throw new Error('Error adding task');
            }
            const data = await response.json();
            return data;  // Return the response after adding the task
        } catch (error) {
            console.log('Error adding task:', error);
            return { error: 'Failed to add task' };
        }
    }
    static async getTasksByMatterId(matterId) {
        try {
            const response = await fetch(`${API_URL}/tasks_by_matter/${matterId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error fetching tasks for matter ID: ${matterId}`);
            }
            const data = await response.json();
            return data;  // Return tasks associated with the matter ID
        } catch (error) {
            console.log(`Error fetching tasks for matter ID ${matterId}:`, error);
            return { error: `Failed to fetch tasks for matter ID: ${matterId}` };
        }
    }
    static async getTasksByMatterAndEmployee(matterId, employeeId) {
        try {
            const response = await fetch(`${API_URL}/tasks_by_matter_employee/${matterId}/${employeeId}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error fetching tasks for matter ID: ${matterId} and employee ID: ${employeeId}`);
            }
            const data = await response.json();
            return data;  // Return tasks associated with both matter ID and employee ID
        } catch (error) {
            console.log(`Error fetching tasks for matter ID ${matterId} and employee ID ${employeeId}:`, error);
            return { error: `Failed to fetch tasks for matter ID: ${matterId} and employee ID: ${employeeId}` };
        }
    }

    static async updateTaskStatusToPending(taskId) {
        try {
            const response = await fetch(`${API_URL}/task-pending/${taskId}`, {
                method: 'PUT',
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error updating task status to Pending for task ID: ${taskId}`);
            }
            const data = await response.json();
            return data;  // Return the response for updating status to "Pending"
        } catch (error) {
            console.log(`Error updating task status to Pending for task ID ${taskId}:`, error);
            return { error: `Failed to update task status to Pending for task ID: ${taskId}` };
        }
    }
    static async updateTaskStatusToInProgress(taskId) {
        try {
            const response = await fetch(`${API_URL}/task-inprogress/${taskId}`, {
                method: 'PUT',
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error updating task status to In Progress for task ID: ${taskId}`);
            }
            const data = await response.json();
            return data;  // Return the response for updating status to "In Progress"
        } catch (error) {
            console.log(`Error updating task status to In Progress for task ID ${taskId}:`, error);
            return { error: `Failed to update task status to In Progress for task ID: ${taskId}` };
        }
    }

    static async updateTaskStatusToComplete(taskId) {
        try {
            const response = await fetch(`${API_URL}/task-complete/${taskId}`, {
                method: 'PUT',
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error updating task status to Completed for task ID: ${taskId}`);
            }
            const data = await response.json();
            return data;  // Return the response for updating status to "Completed"
        } catch (error) {
            console.log(`Error updating task status to Completed for task ID ${taskId}:`, error);
            return { error: `Failed to update task status to Completed for task ID: ${taskId}` };
        }
    }

    static async getTasksByMonth(matterId, month) {
        try {
            const response = await fetch(`${API_URL}/tasks_by_month/${matterId}/${month}`, {
                headers: await getAuthHeaders()
            });
            if (!response.ok) {
                throw new Error(`Error fetching tasks for matter ID: ${matterId} in month: ${month}`);
            }
            const data = await response.json();
            return data;  // Return tasks filtered by matter ID and month
        } catch (error) {
            console.log(`Error fetching tasks for matter ID ${matterId} in month ${month}:`, error);
            return { error: `Failed to fetch tasks for matter ID: ${matterId} in month: ${month}` };
        }
    }

}

export default TaskService;
