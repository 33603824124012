import React, { useState, useEffect } from 'react';
import './DCTable.css';
import DeadlineChecklistService from '../../../../../AppSettings/services/DeadlineChecklistService';
import { useParams } from 'react-router-dom';
import AddDC from './AddDC/AddDC';

const DCTable = () => {
    const [data, setData] = useState([]);
    const [view, setView] = useState('Deadlines');
    const [showAddDC, setShowAddDC] = useState(false); // New state for modal visibility
    const { matterId } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = view === 'Deadlines'
                    ? await DeadlineChecklistService.getDeadlinesByMatterId(matterId)
                    : await DeadlineChecklistService.getChecklistItemsByMatterId(matterId);
                setData(response);
            } catch (error) {
                console.error(`Error fetching ${view.toLowerCase()} data:`, error);
            }
        };
        fetchData();
    }, [view, matterId]);

    const handleOverlayClick = (e) => {
        // Close modal if the clicked target is the overlay
        if (e.target.className.includes('dc-modal-overlay')) {
            setShowAddDC(false);
        }
    };

    return (
        <div className="dc-container">
            <div className="dc-header">
                <div className="dc-left">
                    <span 
                        className={view === 'Deadlines' ? 'active' : ''} 
                        onClick={() => setView('Deadlines')}
                    >
                        Deadlines
                    </span>
                    <span className="separator">|</span>
                    <span 
                        className={view === 'Checklist' ? 'active' : ''} 
                        onClick={() => setView('Checklist')}
                    >
                        Checklist
                    </span>
                </div>
                <button 
                    className="dc-add-button" 
                    onClick={() => setShowAddDC(true)} // Show modal on click
                >
                    +
                </button>
            </div>
         
            {data.slice(0, 3).map((item, index) => (
                <div key={index} className="dc-item">
                    <div className="dc-title">
                        {view === 'Deadlines' ? item.deadline_type : item.description}
                    </div>
                    <div className="dc-meta">
                        {view === 'Deadlines' ? (
                            <>
                                <span>Status: {item.status}</span>
                                {item.due_date && <span className="dc-assigned">Due Date: {item.due_date}</span>}
                            </>
                        ) : (
                            <>
                                <span>Status: {item.status}</span>
                                {item.matterid && <span className="dc-assigned">ID: {item.matterid}</span>}
                            </>
                        )}
                    </div>
                </div>
            ))}

            {showAddDC && (
                <div className="dc-modal-overlay" onClick={handleOverlayClick}>
                    <div className="dc-modal-content">
                        <AddDC onClose={() => setShowAddDC(false)} /> {/* Close modal on AddDC action */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DCTable;
