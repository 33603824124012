import React, { useEffect, useState } from 'react';
import getAuthHeaders from '../../../AppSettings/routes/functions/getAuthHeaders';
import { useMsal } from '@azure/msal-react';
import './PersonalWidget.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const PersonalWidget = () => {
  const { accounts } = useMsal();
  const [employee, setEmployee] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [newBilledRate, setNewBilledRate] = useState('');

  useEffect(() => {
    fetchEmployee();
  }, []);

  const fetchEmployee = async () => {
    try {
      const { oid } = accounts[0].idTokenClaims;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/getemployee/${oid}`, {
        headers: await getAuthHeaders(),
      });
      const data = await response.json();
      setEmployee(data);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  const handleUpdateEmployee = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/employee/${employee.employeeid}`, {
        method: 'PUT',
        headers: await getAuthHeaders(),
        body: JSON.stringify({ billedrate: newBilledRate }),
      });
      if (response.ok) {
        setEmployee({ ...employee, billedrate: newBilledRate });
        setEditMode(false);
      } else {
        console.error('Failed to update billed rate');
      }
    } catch (error) {
      console.error('Error updating billed rate:', error);
    }
  };

  return (
    <div className="personal-widget">
      <div className="personal-card">
        <div className="profile-avatar">
          <div className="avatar-placeholder" />
        </div>
        <h3 className="profile-name">{`${employee.firstname || ''} ${employee.lastname || ''}`}</h3>
        <div className="profile-details">
          <div className="profile-info-item"><strong>Title:</strong> {employee.title || ''}</div>
          <div className="profile-info-item"><strong>Age:</strong> {employee.age || ''}</div>
          <div className="profile-info-item"><strong>Email:</strong> {employee.email || ''}</div>
          <div className="profile-info-item"><strong>Phone:</strong> {employee.phone || ''}</div>
          <div className="profile-info-item"><strong>City:</strong> {employee.city || ''}</div>
        </div>
        {editMode ? (
          <form className="edit-form" onSubmit={handleUpdateEmployee}>
            <label htmlFor="newBilledRate">Billed Rate ($/hr): </label>
            <input
              type="number"
              id="newBilledRate"
              value={newBilledRate}
              placeholder={employee.billedrate}
              onChange={(e) => setNewBilledRate(e.target.value)}
              required
              min="0"
            />
            <div className="edit-buttons">
              <button type="submit">Save</button>
              <button type="button" onClick={() => setEditMode(false)}>Cancel</button>
            </div>
          </form>
        ) : (
          <div className="billed-rate">
            <strong>Billed Rate ($/hr): {employee.billedrate}</strong>
            <button className="edit-button" onClick={() => setEditMode(true)}><FontAwesomeIcon icon={faPenToSquare} /></button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalWidget;
