import React, { useEffect, useState } from 'react';
import './MatterProgressBar.css';
import WorkflowService from '../../../../AppSettings/services/WorkflowService';
import MatterService from '../../../../AppSettings/services/MatterService';
import { useParams } from 'react-router-dom';
import getAuthHeaders from '../../../../AppSettings/routes/functions/getAuthHeaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';    
const MatterProgressbar = () => {
    const { matterId } = useParams();
    const [statuses, setStatuses] = useState([]); // Milestones in the workflow
    const [progress, setProgress] = useState(0); // Progress percentage
    const [currentIndex, setCurrentIndex] = useState(0); // Index of current status
    const [showMilestonePopup, setShowMilestonePopup] = useState(false); // Toggle popup

    useEffect(() => {
        const fetchMatterDetailsAndWorkflow = async () => {
            try {
                const matterDetails = await MatterService.getMatterDetails(matterId);
                const { workflowid: workflowId, status: currentStatus } = matterDetails;
    
                if (!workflowId) {
                    console.error(`No workflowId found for matterId: ${matterId}`);
                    return;
                }
    
                const workflow = await WorkflowService.fetchAssignedWorkflow(workflowId);
                const milestones = workflow.milestones || [];
    
                if (!milestones.length) {
                    console.error(`No milestones found for workflowId: ${workflowId}`);
                    return;
                }
    
                setStatuses(milestones);
                const initialIndex = milestones.indexOf(currentStatus);
                setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
                updateProgress(initialIndex !== -1 ? initialIndex : 0, milestones.length);
            } catch (error) {
                console.error('Error fetching matter or workflow details:', error);
            }
        };
    
        if (matterId) fetchMatterDetailsAndWorkflow();
    }, [matterId]);
    
    const updateProgress = (index, total) => {
        const percentage = (index / (total - 1)) * 100;
        setProgress(total > 1 ? percentage : 0);
    };

    const handleMilestoneClick = async (index) => {
        const newStatus = statuses[index];
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/matter-status/${matterId}`, {
                method: 'PUT',
                headers: await getAuthHeaders(),
                body: JSON.stringify({ status: newStatus }),
            });
    
            if (response.ok) {
                setCurrentIndex(index);
                updateProgress(index, statuses.length);
            } else {
                console.error('API call to update matter status was not successful');
            }
        } catch (error) {
            console.error('Error updating matter status:', error);
        }
    };

    return (
        <div className="Matter-progress-bar-container">
            <button
                className="Matter-progress-popup-toggle"
                onClick={() => setShowMilestonePopup(!showMilestonePopup)}
            >
                <FontAwesomeIcon icon={faUpRightFromSquare} />
            </button>
    
            <div className="Matter-status-progress-container">
                {/* Current Status */}
                <div className="Matter-current-status">
                    <strong>Current Status:</strong> {statuses[currentIndex] || "Not Available"}
                </div>
    
                {/* Progress Bar */}
                <div className="Matter-progress-bar">
                    <div
                        className="Matter-progress-fill"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
            </div>
    
            {/* Popup Modal */}
            {showMilestonePopup && (
                <div className="Matter-popup-overlay" onClick={() => setShowMilestonePopup(false)}>
                    <div
                        className="Matter-popup-content"
                        onClick={(e) => e.stopPropagation()} // Prevents overlay click from closing
                    >
                        <div className="Matter-popup-milestones">
                            {statuses.map((status, index) => (
                                <span
                                    key={index}
                                    className={`Matter-popup-status ${index === currentIndex ? 'active' : ''}`}
                                    onClick={() => {
                                        handleMilestoneClick(index);
                                        setShowMilestonePopup(false); // Close popup on selection
                                    }}
                                >
                                    {status}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MatterProgressbar;
