import React, { useState, useEffect } from "react";
import "./GenerateInvoice.css";
import getAuthHeaders from "../../../../../AppSettings/routes/functions/getAuthHeaders";
import generateInvoicePDF from "./InvoicePDFGen";
import ClientService from "../../../../../AppSettings/services/ClientService";
import MatterService from "../../../../../AppSettings/services/MatterService";
import InvoiceView from "./InvoiceView";

const GenerateInvoice = ({ matterId }) => {
  const [matterDetails, setMatterDetails] = useState([]);
  const [client, setClient] = useState([]);
  const [invoiceClient, setInvoiceClient] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [clientCompany, setClientCompany] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [showFixedFeesDropdown, setShowFixedFeesDropdown] = useState(false);
  const [showBillableTimesDropdown, setShowBillableTimesDropdown] =
    useState(false);
  const [showDisbursementsDropdown, setShowDisbursementsDropdown] =
    useState(false);
  const [billableTimes, setBillableTimes] = useState([]);
  const [fixedFees, setFixedFees] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [taxRate, setTaxRate] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [disbursements, setDisbursements] = useState([]);

  const [showFixedFeesModal, setShowFixedFeesModal] = useState(false);
  const [showBillableTimesModal, setShowBillableTimesModal] = useState(false);
  const [showDisbursementsModal, setShowDisbursementsModal] = useState(false);

  const closeModal = () => {
    setShowFixedFeesModal(false);
    setShowBillableTimesModal(false);
    setShowDisbursementsModal(false);
  };

  function calculateTotalAmount(lineItems, discountRate, taxRate) {
    let totalAmount = 0;
    lineItems.forEach((item) => {
      const amount = parseFloat(item.amount) || 0;
      const discount = parseFloat(item.discount) || 0;
      const quantity = parseInt(item.qty) || 1;
      const discountAmount = (amount * discount) / 100;
      const finalAmount = amount - discountAmount;
      const totalItemAmount = finalAmount * quantity;
      totalAmount += totalItemAmount;
    });
    const discountAmount = totalAmount * (discountRate / 100);
    const subtotalAfterDiscount = totalAmount - discountAmount;
    const taxAmount = subtotalAfterDiscount * (taxRate / 100);
    const finalTotal = subtotalAfterDiscount + taxAmount;
    return finalTotal;
  }

  async function handleGenerateInvoice(e) {
    e.preventDefault();

    try {
      const data = {
        matterid: matterId,
        clientid: matterId.split("-")[0],
        due_date: invoiceDate,
        discount: discount,
        tax_rate: taxRate,
        total_amount: calculateTotalAmount(lineItems, discount, taxRate),
        line_items: lineItems,
      };

      // Make the POST request to create a new invoice
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/invoice`,
        {
          method: "POST",
          headers: await getAuthHeaders(),
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create invoice");
      }

      const responseData = await response.json();
      const invoiceId = responseData.invoiceId;

      generateInvoicePDF(
        matterId,
        {
          name: invoiceClient,
          email: clientEmail,
          phone: clientPhone,
          address: clientAddress,
          company: clientCompany,
        },
        invoiceDate,
        matterDetails.mattername,
        lineItems,
        discount,
        taxRate,
        invoiceId,
        matterDetails.application_no,
        matterDetails.country
      );
    } catch (error) {
      console.log("Error creating invoice:", error.message);
    }
  }

  const fetchFixedFees = async () => {
    // Toggle visibility first
    setShowFixedFeesDropdown((prevState) => !prevState);

    // If we are going to show the fees, fetch them
    if (!showFixedFeesDropdown) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/fixed_fees`,
          {
            headers: await getAuthHeaders(),
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setFixedFees(data);
      } catch (error) {
        console.error("Error fetching fixed fees:", error);
      }
    }
  };

  const fetchBillableTimes = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`,
        {
          headers: await getAuthHeaders(),
        }
      );
      const data = await response.json();

      // Filter out billable times that have an invoice ID
      const filteredBillableTimes = data.filter((time) => !time.invoiceid);

      console.log("Filtered Billable Times:", filteredBillableTimes); // Debug: Check the filtered data
      setBillableTimes(filteredBillableTimes); // Store only the billable times without an invoice ID
    } catch (error) {
      console.error(
        "Error fetching billable times for matterId:",
        matterId,
        error
      );
    }
  };

  const handleToggleFixedFees = async () => {
    setShowFixedFeesModal((prev) => !prev);
    if (!showFixedFeesModal && fixedFees.length === 0) {
      await fetchFixedFees();
    }
  };

  const handleToggleDisbursements = async () => {
    setShowDisbursementsModal((prev) => !prev);
    if (!showDisbursementsModal && disbursements.length === 0) {
      await fetchDisbursements();
    }
  };

  const addDisbursementToLineItems = (disbursement) => {
    const newLineItem = {
      description: disbursement.description,
      qty: 1, // Assuming quantity is 1 for disbursements
      amount: disbursement.fees,
      discount: 0, // Assuming no discount for disbursements
    };

    setLineItems([...lineItems, newLineItem]);
  };

  const fetchDisbursements = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/agent_disbursements/${matterId}`,
        {
          headers: await getAuthHeaders(),
        }
      );
      const data = await response.json();
      setDisbursements(data);
    } catch (error) {
      console.log("Error fetching disbursements:", error);
    }
  };

  const generatePreBill = async (event) => {
    event.preventDefault();

    try {
      // Fetch all data concurrently
      const [
        assignedFeesResponse,
        disbursementsResponse,
        billableTimesResponse,
      ] = await Promise.all([
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/assigned_fixed_fees/${matterId}`,
          { headers: await getAuthHeaders() }
        ),
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/agent_disbursements/${matterId}`,
          { headers: await getAuthHeaders() }
        ),
        fetch(
          `${process.env.REACT_APP_API_BASE_URL}/get_billed_time/${matterId}`,
          { headers: await getAuthHeaders() }
        ),
      ]);

      // Await and process all responses
      const [assignedFees, disbursements, billableTimes] = await Promise.all([
        assignedFeesResponse.json(),
        disbursementsResponse.json(),
        billableTimesResponse.json(),
      ]);

      // Filter billable times to include only those without an invoice ID
      const filteredBillableTimes = billableTimes.filter(
        (time) => !time.invoiceid
      );

      // Map each data set to the line item format
      const newLineItems = [
        ...assignedFees.map((fee) => ({
          type: "fixed fee",
          id: fee.assignedid,
          description: `${fee.fee_code} - ${fee.narrative}`,
          qty: 1, // Assuming quantity is 1 for fees
          amount: fee.fees,
          discount: 0, // Assuming no discount for fees
        })),
        ...disbursements.map((disbursement) => ({
          type: "disbursement",
          id: disbursement.disbursementid,
          description: disbursement.description,
          qty: 1, // Assuming quantity is 1 for disbursements
          amount: disbursement.fees,
          discount: 0, // Assuming no discount for disbursements
        })),
        ...filteredBillableTimes.map((time) => ({
          type: "billed time",
          id: time.bt_id,
          description: `Billable Time - ${time.employee_name} - ${time.date} - ${time.time_recorded}`,
          qty: 1, // Assuming quantity is 1 for billable times
          amount: time.amount, // Assuming 'amount' is a property of billable times
          discount: 0, // Assuming no discount for billable times
        })),
      ];

      // Update line items state
      setLineItems((currentItems) => [...currentItems, ...newLineItems]);
    } catch (error) {
      console.error("Error generating pre bill:", error);
    }
  };

  const fetchMatterDetails = async () => {
    try {
      const matterData = await MatterService.getMatterDetails(matterId);
      if (matterData) {
        setMatterDetails(matterData);
      }
    } catch (error) {
      console.error("Error fetching matter details:", error);
    }
  };

  const fetchClientDetails = async () => {
    try {
      const clientId = matterId.split("-")[0];
      const clientData = await ClientService.getClientById(clientId);
      if (clientData) {
        setClient(clientData);
        setInvoiceClient(`${clientData.firstname} ${clientData.lastname}`);
        setClientEmail(clientData.email);
        setClientPhone(clientData.phone);
        setClientAddress(clientData.address);
        setClientCompany(clientData.company);
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
    }
  };

  const handleToggleBillableTimes = async () => {
    setShowBillableTimesModal((prev) => !prev);
    if (!showBillableTimesModal && billableTimes.length === 0) {
      await fetchBillableTimes();
    }
  };

  const addAllBillableTimes = () => {
    billableTimes.forEach((BT) => {
      const newLineItem = {
        description: `Billable Time - ID: ${BT.bt_id} - Time Spent: ${BT.time_recorded}`,
        qty: 1,
        amount: isNaN(BT.amount) ? 0 : BT.amount, // Use the correct amount for each billable time
        discount: 0,
      };
      setLineItems((currentItems) => [...currentItems, newLineItem]);
    });
    closeModal();
  };

  // Update a line item
  const updateLineItem = (index, field, value) => {
    const newLineItems = lineItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setLineItems(newLineItems);
  };

  const addLineItem = () => {
    setLineItems([
      ...lineItems,
      { description: "", qty: "", amount: 0, discount: 0 },
    ]);
  };

  const removeLineItem = (index) => {
    const newLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(newLineItems);
  };

  const addFixedFeeToLineItems = (fee) => {
    const newLineItem = {
      description: `${fee.fee_code} - ${fee.narrative}`,
      amount: fee.fees,
      qty: 1,
      discount: 0,
    };

    setLineItems([...lineItems, newLineItem]);
  };

  const addBillableTimeToLineItems = (BT) => {
    const newLineItem = {
      description: `Billable Time - ID: ${BT.bt_id} - Time Spent: ${BT.time_recorded}`,
      qty: 1,
      amount: isNaN(BT.amount) ? 0 : BT.amount, // Use the amount directly from the billable time entry
      discount: 0,
    };
    console.log(newLineItem);
    setLineItems([...lineItems, newLineItem]);
  };

  useEffect(() => {
    fetchMatterDetails();
    fetchClientDetails();
  }, []);

  return (
    <div className="invoice-page-container">
      <div className="generate-invoice-container">
        <div className="Invoice-Tab-Container">
          <div className="billing">
            <form onSubmit={handleGenerateInvoice}>
              {/* Left Column */}
              <div className="left-column">
                <div className="form-group">
                  <label htmlFor="matterDetails">Matter</label>
                  <input
                    id="matterDetails"
                    type="text"
                    value={` ${matterDetails.matterid} ${matterDetails.mattername}`}
                    readOnly
                    placeholder="Matter Name"
                  />
                </div>{" "}
                <div className="form-group">
                  <label htmlFor="clientName">Client Name</label>
                  <input
                    id="clientName"
                    type="string"
                    value={invoiceClient}
                    onChange={(e) => setInvoiceClient(e.target.value)}
                    placeholder="Client Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="clientEmail">Client Email</label>
                  <input
                    id="clientEmail"
                    type="email"
                    value={clientEmail}
                    onChange={(e) => setClientEmail(e.target.value)}
                    placeholder="Client Email"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="clientPhone">Client Phone</label>
                  <input
                    id="clientPhone"
                    type="tel"
                    value={clientPhone}
                    onChange={(e) => setClientPhone(e.target.value)}
                    placeholder="Client Phone"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="clientaddress">Client Address</label>
                  <input
                    id="clientaddress"
                    type="address"
                    value={clientAddress}
                    onChange={(e) => setClientAddress(e.target.value)}
                    placeholder="Client Address"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="clientcompany">Client Company</label>
                  <input
                    id="clientcompany"
                    type="company"
                    value={clientCompany}
                    onChange={(e) => setClientCompany(e.target.value)}
                    placeholder="Client Company"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="invoiceDate">Invoice Due Date</label>
                  <input
                    id="invoiceDate"
                    type="date"
                    value={invoiceDate}
                    onChange={(e) => setInvoiceDate(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="right-column">
                <div className="APP-COUNTRYform-group APP-COUNTRY-row">
                  <div className="APP-COUNTRYform-group-half">
                    <div className="form-group">
                      <label htmlFor="country">Country</label>
                      <input
                        id="country"
                        type="text"
                        value={matterDetails.country || ""}
                        onChange={(e) =>
                          setMatterDetails({
                            ...matterDetails,
                            country: e.target.value,
                          })
                        }
                        placeholder="Country"
                      />
                    </div>
                  </div>
                  <div className="APP-COUNTRYform-group-half">
                    <div className="form-group">
                      <label htmlFor="applicationNo">Application No</label>
                      <input
                        id="applicationNo"
                        type="number"
                        value={matterDetails.application_no || ""}
                        onChange={(e) =>
                          setMatterDetails({
                            ...matterDetails,
                            application_no: e.target.value,
                          })
                        }
                        placeholder="App #"
                      />
                    </div>
                  </div>
                </div>
                <div className="billing-item">
                  <h4>Billing Items:</h4>
                  <div className="FF-BT-Container">
                    <button
                      type="button"
                      onClick={handleToggleFixedFees}
                      className="BT-Button"
                    >
                      {showFixedFeesModal ? "Close Fixed Fees" : "Fixed Fees"}
                    </button>
                    <button
                      type="button"
                      onClick={handleToggleBillableTimes}
                      className="BT-Button"
                    >
                      {showBillableTimesModal
                        ? "Close Billable Times"
                        : "Billable Times"}
                    </button>
                    <button
                      type="button"
                      onClick={handleToggleDisbursements}
                      className="BT-Button"
                    >
                      {showDisbursementsModal
                        ? "Close Disbursements"
                        : "Disbursements"}
                    </button>

                    {/* Fixed Fees Modal */}
                    {showFixedFeesModal && (
                      <div className="modal" onClick={closeModal}>
                        <div
                          className="modal-content"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2>Fixed Fees</h2>
                          <ul>
                            {fixedFees.map((fee, index) => (
                              <li key={index} className="modal-entry">
                                <div className="modal-entry-container">
                                  <button
                                    onClick={() => {
                                      addFixedFeeToLineItems(fee);
                                    }}
                                    type="button"
                                    className="modal-invoice-button"
                                  >
                                    Add
                                  </button>
                                  <span>
                                    <strong>
                                      {fee.fee_code} - {fee.narrative} - $
                                      {fee.fees}
                                    </strong>
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <button
                            onClick={closeModal}
                            className="modal-invoice-button"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Billable Times Modal */}
                    {showBillableTimesModal && (
                      <div className="modal" onClick={closeModal}>
                        <div
                          className="modal-content"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2>Billable Times</h2>
                          <button
                            onClick={addAllBillableTimes}
                            type="button"
                            className="modal-add-all-button"
                          >
                            Add All Billable Times
                          </button>
                          <ul>
                            {billableTimes.map((BT, index) => (
                              <li
                                key={index}
                                className="modal-billable-time-entry"
                              >
                                <strong>
                                  ID: {BT.bt_id} <br /> Time Spent: &nbsp;
                                  {BT.time_recorded} <br />
                                  Date: {BT.date}
                                </strong>
                                <p>
                                  <strong>Notes:</strong> {BT.notes}
                                </p>
                                <button
                                  className="modal-invoice-button"
                                  type="button"
                                  onClick={() => addBillableTimeToLineItems(BT)}
                                >
                                  Add
                                </button>
                              </li>
                            ))}
                          </ul>
                          <button
                            onClick={closeModal}
                            className="modal-invoice-button"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Disbursements Modal */}
                    {showDisbursementsModal && (
                      <div className="modal" onClick={closeModal}>
                        <div
                          className="modal-content"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <h2>Disbursements</h2>
                          <ul>
                            {disbursements.map((disbursement, index) => (
                              <li key={index} className="modal-entry">
                                <div className="modal-entry-container">
                                  <button
                                    onClick={() =>
                                      addDisbursementToLineItems(disbursement)
                                    }
                                    type="button"
                                    className="modal-invoice-button"
                                  >
                                    Add
                                  </button>
                                  <span>
                                    <strong>
                                      {disbursement.description} - $
                                      {disbursement.fees}
                                    </strong>
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ul>
                          <button
                            onClick={closeModal}
                            className="modal-invoice-button"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <table className="line-item-table">
                  <thead>
                    <tr>
                      <div className="line-item-header">
                        Service Description
                        <button
                          type="button"
                          className="add-line-item-button"
                          onClick={addLineItem}
                        >
                          +
                        </button>
                      </div>
                    </tr>
                  </thead>
                  <tbody>
                    {lineItems.map((item, index) => (
                      <tr key={index} className="line-item">
                        <td>
                          <input
                            type="text-SDFinancial"
                            value={item.description}
                            onChange={(e) =>
                              updateLineItem(
                                index,
                                "description",
                                e.target.value
                              )
                            }
                            placeholder="Service Description"
                            className="help-input"
                            required
                            title="Enter a description for the service"
                          />
                          <div class="help-box">
                            Please enter a short description for this service.
                          </div>
                        </td>
                        <td>
                          <input
                            type="text-SDFinancial"
                            value={item.qty}
                            onChange={(e) =>
                              updateLineItem(index, "qty", e.target.value)
                            }
                            placeholder="QTY"
                            className="help-input"
                            required
                          />
                          <div class="help-box">
                            Please enter a quantity for this service.
                          </div>
                        </td>
                        <td>
                          <input
                            type="number-SDFinancial"
                            value={item.amount} // Ensure this reflects the correct amount
                            onChange={(e) =>
                              updateLineItem(
                                index,
                                "amount",
                                e.target.value ? parseFloat(e.target.value) : 0
                              )
                            }
                            placeholder="Amount"
                            className="help-input"
                            title="Enter an amount for this service"
                            required
                          />
                          <div class="help-box">
                            Please enter an amount for this service.
                          </div>
                        </td>
                        <td>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={item.discount}
                            onChange={(e) =>
                              updateLineItem(
                                index,
                                "discount",
                                e.target.value ? parseFloat(e.target.value) : 0
                              )
                            }
                            className="help-input"
                            title="Enter a discount for this service (if required)"
                            placeholder="Discount"
                          />
                          <div class="help-box">
                            Please enter a discount for this service (if
                            required).
                          </div>
                        </td>

                        <td className="button-container">
                          <button
                            type="button"
                            onClick={() => removeLineItem(index)}
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="form-group">
                  <label htmlFor="discount">Discount (%)</label>
                  <input
                    id="discount"
                    type="number"
                    value={discount}
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    placeholder="Discount"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="taxRate">Tax Rate (%)</label>
                  <input
                    id="taxRate"
                    type="number"
                    value={taxRate} // Add state variable for tax rate
                    onChange={(e) => setTaxRate(parseFloat(e.target.value))}
                    placeholder="Tax Rate"
                  />
                </div>
                <div className="Invoice-button-group">
                  <h2 className="Invoice-section-toggle">
                    <button type="submit" className="pdf-button">
                      Generate Invoice
                    </button>
                  </h2>
                  <h2 className="Invoice-section-toggle">
                    <button onClick={generatePreBill} className="BT-button">
                      Generate Pre Bill
                    </button>
                  </h2>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="invoice-view-container">
        <InvoiceView
          client={{
            name: invoiceClient,
            email: clientEmail,
            phone: clientPhone,
            address: clientAddress,
            company: clientCompany,
          }}
          matterId={matterId}
          matterName={matterDetails.mattername}
          lineItems={lineItems}
          discountRate={discount}
          taxRate={taxRate}
          application_no={matterDetails.application_no || ""}
          dueDate={invoiceDate}
          country={matterDetails.country}
        />
      </div>
    </div>
  );
};

export default GenerateInvoice;
