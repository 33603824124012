import React, { useEffect, useState } from 'react';
import Templates from '../Templates/Templates';
import ProfileWorkflow from '../Create&UpdateWorkflow/ProflieWorkflow';
import getAuthHeaders from '../../../AppSettings/routes/functions/getAuthHeaders';
import './Rules.css';
const Rules = () => {
    const [activeSection, setActiveSection] = useState('deadlineRules'); // Default section
    const [newRule, setNewRule] = useState({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
  const [deadlineRules, setDeadlineRules] = useState([]);

    useEffect(() => {

        fetchDeadlineRulesTable();
      }, []);
    
      const fetchDeadlineRulesTable = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/deadline-rules`, {
            headers: await getAuthHeaders()
          });
          const data = await response.json();
          console.log('Fetched deadline rules:', data);
          setDeadlineRules(data);
        } catch (error) {
          console.log('Error fetching deadline rules:', error);
        }
      };
    const handleSaveDeadlineRuleToTable = async (e) => {
        e.preventDefault();
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/save_deadline_rule`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...await getAuthHeaders()
            },
            body: JSON.stringify(newRule)
          });
          if (response.ok) {
            fetchDeadlineRulesTable(); // Refresh rules after saving
            setNewRule({ dateField: '', duration: '', durationType: 'months', matterType: '', country: '', internalExternal: '' });
          } else {
            console.error('Failed to save the deadline rule to the deadline_rules table');
          }
        } catch (error) {
          console.log('Error saving the deadline rule to the deadline_rules table:', error);
        }
      };
    
    
    return (
        <div className="Rule-Building-Container">
            <div className="rule-builder-container">
                <h2>Rule Builder</h2>
            </div>
         <div className="DR-TP-button-container">
          <button onClick={() => setActiveSection('MatterRules')} className={`DR-button ${activeSection === 'MatterRules' ? 'active' : ''}`}>Matter Rules</button>
          <button onClick={() => setActiveSection('TaskRules')} className={`DR-button ${activeSection === 'TaskRules' ? 'active' : ''}`}>Task Rules</button>
          <button onClick={() => setActiveSection('deadlineRules')} className={`DR-button ${activeSection === 'deadlineRules' ? 'active' : ''}`}>Deadline Rules</button>
          <button onClick={() => setActiveSection('ChecklistRules')} className={`DR-button ${activeSection === 'ChecklistRules' ? 'active' : ''}`}>Checklist Rules</button>
          <button onClick={() => setActiveSection('EmailRules')} className={`DR-button ${activeSection === 'EmailRules' ? 'active' : ''}`}>Email Rules</button>

        </div>
        {activeSection === 'MatterRules' && (
          <div className="deadline-rules">
            {/* <h2>Manage Matter Rules</h2> */}
             <form className="deadline-form" onSubmit={handleSaveDeadlineRuleToTable}>
                     <label>Select Matter Type:</label>
                     <select
                     value={newRule.dateField}
                     onChange={(e) => setNewRule({ ...newRule, dateField: e.target.value })}
                     required
                     >
                     <option value="">Select Field</option>
                     <option value="opendate">Open Date</option>
                     <option value="expiry_date">Expiry Date</option>
                     <option value="office_action_date">Office Action Date</option>
                     <option value="application_date">Application Date</option>
                     <option value="registration_date">Registration Date</option>
                     <option value="publication_date">Publication Date</option>
                     </select>

                     <label>Created in:</label>
                     <input
                     type="text"
                     value={newRule.duration}
                     onChange={(e) => setNewRule({ ...newRule, duration: e.target.value })}
                     required
                     min="1"
                     placeholder="Enter Number"
                     />

                     <label>Client Type:</label>
                     <select
                     value={newRule.durationType}
                     onChange={(e) => setNewRule({ ...newRule, durationType: e.target.value })}
                     >
                     <option value="days">Days</option>
                     <option value="weeks">Weeks</option>
                     <option value="months">Months</option>
                     </select>

                     <label>Data Feilds:</label>
                     <select
                     value={newRule.matterType}
                     onChange={(e) => setNewRule({ ...newRule, matterType: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="Trademark">Trademark</option>
                     <option value="Patent">Patent</option>
                     <option value="Strategy">Strategy</option>
                     <option value="Design">Design</option>
                     <option value="Copyright">Copyright</option>
                     <option value="Commercial">Commercial</option>
                     <option value="Cautionary_Notice">Cautionary Notice</option>
                     <option value="Utility_Model">Utility Model</option>
                     </select>

                     <label>Documents Assigned & Filled:</label>
                     <input
                     type="text"
                     value={newRule.country}
                     onChange={(e) => setNewRule({ ...newRule, country: e.target.value })}
                     placeholder="Enter Country"
                     />

                     <label>Internal/External:</label>
                     <select
                     value={newRule.internalExternal}
                     onChange={(e) => setNewRule({ ...newRule, internalExternal: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="internal">Internal</option>
                     <option value="external">External</option>
                     </select>

                     <div className="button-wrapper">
                            <button type="submit">Save</button>
                     </div>
                     </form>

          </div>
        )}
                {activeSection === 'TaskRules' && (
          <div className="deadline-rules">
            {/* <h2>Manage Task Creation Rules</h2> */}
             <form className="deadline-form" onSubmit={handleSaveDeadlineRuleToTable}>
                     <label>Select Matter Type:</label>
                     <select
                     value={newRule.dateField}
                     onChange={(e) => setNewRule({ ...newRule, dateField: e.target.value })}
                     required
                     >
                     <option value="">Select Field</option>
                     <option value="opendate">Open Date</option>
                     <option value="expiry_date">Expiry Date</option>
                     <option value="office_action_date">Office Action Date</option>
                     <option value="application_date">Application Date</option>
                     <option value="registration_date">Registration Date</option>
                     <option value="publication_date">Publication Date</option>
                     </select>


                     <label>Task Items:</label>
                     <input
                     type="text"
                     value={newRule.country}
                     onChange={(e) => setNewRule({ ...newRule, country: e.target.value })}
                     placeholder="Enter Task Item"
                     />


                     <label>Documents Assigned & Filled:</label>
                     <select
                     value={newRule.matterType}
                     onChange={(e) => setNewRule({ ...newRule, matterType: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="Trademark">Trademark</option>
                     <option value="Patent">Patent</option>
                     <option value="Strategy">Strategy</option>
                     <option value="Design">Design</option>
                     <option value="Copyright">Copyright</option>
                     <option value="Commercial">Commercial</option>
                     <option value="Cautionary_Notice">Cautionary Notice</option>
                     <option value="Utility_Model">Utility Model</option>
                     </select>

                     <label>Internal/External:</label>
                     <select
                     value={newRule.internalExternal}
                     onChange={(e) => setNewRule({ ...newRule, internalExternal: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="internal">Internal</option>
                     <option value="external">External</option>
                     </select>

                     <div className="button-wrapper">
                            <button type="submit">Save Rule</button>
                     </div>
                     </form>

          </div>
        )}



        {activeSection === 'deadlineRules' && (
          <div className="deadline-rules">
            {/* <h2>Manage Deadline Rules</h2> */}
             <form className="deadline-form" onSubmit={handleSaveDeadlineRuleToTable}>
                     <label>Select Date Field:</label>
                     <select
                     value={newRule.dateField}
                     onChange={(e) => setNewRule({ ...newRule, dateField: e.target.value })}
                     required
                     >
                     <option value="">Select Field</option>
                     <option value="opendate">Open Date</option>
                     <option value="expiry_date">Expiry Date</option>
                     <option value="office_action_date">Office Action Date</option>
                     <option value="application_date">Application Date</option>
                     <option value="registration_date">Registration Date</option>
                     <option value="publication_date">Publication Date</option>
                     </select>

                     <label>Duration:</label>
                     <input
                     type="text"
                     value={newRule.duration}
                     onChange={(e) => setNewRule({ ...newRule, duration: e.target.value })}
                     required
                     min="1"
                     placeholder="Enter Number"
                     />

                     <label>Duration Type:</label>
                     <select
                     value={newRule.durationType}
                     onChange={(e) => setNewRule({ ...newRule, durationType: e.target.value })}
                     >
                     <option value="days">Days</option>
                     <option value="weeks">Weeks</option>
                     <option value="months">Months</option>
                     </select>

                     <label>Matter Type:</label>
                     <select
                     value={newRule.matterType}
                     onChange={(e) => setNewRule({ ...newRule, matterType: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="Trademark">Trademark</option>
                     <option value="Patent">Patent</option>
                     <option value="Strategy">Strategy</option>
                     <option value="Design">Design</option>
                     <option value="Copyright">Copyright</option>
                     <option value="Commercial">Commercial</option>
                     <option value="Cautionary_Notice">Cautionary Notice</option>
                     <option value="Utility_Model">Utility Model</option>
                     </select>

                     <label>Country:</label>
                     <input
                     type="text"
                     value={newRule.country}
                     onChange={(e) => setNewRule({ ...newRule, country: e.target.value })}
                     placeholder="Enter Country"
                     />

                     <label>Internal/External:</label>
                     <select
                     value={newRule.internalExternal}
                     onChange={(e) => setNewRule({ ...newRule, internalExternal: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="internal">Internal</option>
                     <option value="external">External</option>
                     </select>

                     <div className="button-wrapper">
                            <button type="submit">Save Rule</button>
                     </div>
                     </form>

          </div>
        )}
        {activeSection === 'ChecklistRules' && (
          <div className="deadline-rules">
            {/* <h2>Manage Checklist Rules</h2> */}
            <form className="deadline-form" onSubmit={handleSaveDeadlineRuleToTable}>
                     <label>Select Matter Type:</label>
                     <select
                     value={newRule.dateField}
                     onChange={(e) => setNewRule({ ...newRule, dateField: e.target.value })}
                     required
                     >
                     <option value="">Select Field</option>
                     <option value="opendate">Open Date</option>
                     <option value="expiry_date">Expiry Date</option>
                     <option value="office_action_date">Office Action Date</option>
                     <option value="application_date">Application Date</option>
                     <option value="registration_date">Registration Date</option>
                     <option value="publication_date">Publication Date</option>
                     </select>

                     <label>Checklist Items:</label>
                     <input
                     type="text"
                     value={newRule.country}
                     onChange={(e) => setNewRule({ ...newRule, country: e.target.value })}
                     placeholder="Enter Checklist Item"
                     />

                     <label>Documents Assigned & Filled:</label>
                     <select
                     value={newRule.matterType}
                     onChange={(e) => setNewRule({ ...newRule, matterType: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="Trademark">Trademark</option>
                     <option value="Patent">Patent</option>
                     <option value="Strategy">Strategy</option>
                     <option value="Design">Design</option>
                     <option value="Copyright">Copyright</option>
                     <option value="Commercial">Commercial</option>
                     <option value="Cautionary_Notice">Cautionary Notice</option>
                     <option value="Utility_Model">Utility Model</option>
                     </select>

            

                     <label>Internal/External:</label>
                     <select
                     value={newRule.internalExternal}
                     onChange={(e) => setNewRule({ ...newRule, internalExternal: e.target.value })}
                     >
                     <option value="">Select Type</option>
                     <option value="internal">Internal</option>
                     <option value="external">External</option>
                     </select>

                     <div className="button-wrapper">
                            <button type="submit">Save Rule</button>
                     </div>
                     </form>

          </div>
        )}
        </div>
    );
};

export default Rules;