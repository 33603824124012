import getAuthHeaders from '../routes/functions/getAuthHeaders';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;

class WorkflowService {
    static async getWorkflows() {
        try {
            const response = await axios.get(`${API_URL}/workflows`, {
                headers: await getAuthHeaders(),
            });
            console.log('Workflows:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching workflows:', error);
            throw new Error('An error occurred while fetching workflows.');
        }
    }

    static async addWorkflow(workflowData) {
        try {
            const response = await axios.post(`${API_URL}/workflows`, workflowData, {
                headers: await getAuthHeaders(),
            });
            console.log('Workflow added:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error adding workflow:', error);
            throw new Error('An error occurred while adding the workflow.');
        }
    }

    static async fetchAssignedWorkflow(workflowId) {
        if (!workflowId) {
            console.error('No workflow ID provided.');
            return null;
        }
        try {
            const response = await axios.get(`${API_URL}/workflows/${workflowId}`, {
                headers: await getAuthHeaders(),
            });

            if (response.status === 200) {
                const workflowData = response.data;
                console.log('Assigned workflow fetched:', workflowData);
                return {
                    name: workflowData.name,
                    milestones: workflowData.milestones,
                };
            } else {
                console.error('Failed to fetch workflow:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('Error fetching assigned workflow:', error);
            throw new Error('An error occurred while fetching the assigned workflow.');
        }
    }
    
    static async updateMatterStatus(matterId, status) {
        try {
            const response = await axios.put(`${API_URL}/matter-status/${matterId}`, 
                { status }, 
                {
                    headers: await getAuthHeaders(),
                });
            console.log(`Matter status updated for ${matterId}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating matter status for ${matterId}:`, error);
            throw new Error('An error occurred while updating the matter status.');
        }
    }
    
    
    static async updateMatterWorkflow(matterId, workflowId) {
        try {
            const response = await axios.put(
                `${API_URL}/matter-workflow/${matterId}`,
                { workflowid: workflowId },
                {
                    headers: await getAuthHeaders(),
                }
            );
            console.log(`Matter workflow updated for ${matterId}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating matter workflow for ${matterId}:`, error);
            throw new Error('An error occurred while updating the matter workflow.');
        }
    }
}

export default WorkflowService;
