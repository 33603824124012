import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconButton.css';

const IconButton = ({ icon, onClick, ariaLabel, style }) => {
  return (
    <button className="icon-button" onClick={onClick} aria-label={ariaLabel} style={style}>
      <FontAwesomeIcon icon={icon} className="icon" />
    </button>
  );
}

export default IconButton;
