import "./Pagination.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";

const Pagination = ({ currPage, onPageChange, pageLimit }) => {
    const handlePageDecrement = () => {
        if (currPage > 1) {
            // Probably do something on matters page as a result of page state changing
            onPageChange(currPage - 1);
        }
    }

    const handlePageIncrement = () => {
        if (currPage < pageLimit) {
            // Probably do something on matters page as a result of page state changing
            onPageChange(currPage + 1);
        }
    }

  return (
    <div className="footer">
      <div>
        <button className="pagination-button" onClick={handlePageDecrement}>
          <FontAwesomeIcon icon={faAnglesLeft} className="pagination-button" />
        </button>
        <span>{currPage} of {pageLimit === 0 ? 1 : pageLimit}</span>
        <button className="pagination-button" onClick={handlePageIncrement}>
          <FontAwesomeIcon icon={faAnglesRight} className="pagination-button" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
