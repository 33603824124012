import React, { useEffect, useState } from 'react';
import './BilliableTarget.css';
import FinancialService from '../../../AppSettings/services/FinancialService';
import { useMsal } from '@azure/msal-react';

// Helper function to parse "time_recorded" from the backend
const parseTime = (timeString) => {
    if (timeString.includes('day')) {
        const [days, time] = timeString.split(', ');
        const dayHours = parseInt(days) * 24;
        const [hours, minutes, seconds] = time.split(':').map(Number);
        return dayHours + hours + minutes / 60 + seconds / 3600;
    } else {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return hours + minutes / 60 + seconds / 3600;
    }
};

const BilliableTarget = () => {
    const [progress, setProgress] = useState(0);
    const { accounts } = useMsal();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // 1. Get employeeId from MSAL
                const account = accounts[0];
                const employeeId = account.idTokenClaims.oid;

                // 2. Fetch annual billable target
                console.log('Fetching Billable Target (annual) for Employee ID:', employeeId);
                let monthlyTarget = 0;

                try {
                    const targetDataResponse = await FinancialService.getBillableTargetsByEmployeeId(employeeId);
                    console.log('Billable Target Response:', targetDataResponse);

                    // The backend returns { success: true, data: [...] }
                    if (targetDataResponse && targetDataResponse.success) {
                        const { data } = targetDataResponse; // The actual array of targets
                        if (data && data.length > 0) {
                            // data[0].billabletarget is the *annual* target.
                            const annualTarget = data[0].billabletarget; 
                            console.log('Annual Target:', annualTarget);

                            // Convert annual target to monthly
                            monthlyTarget = annualTarget / 12; 
                            console.log('Calculated Monthly Target:', monthlyTarget);
                        } else {
                            console.log('No Billable Target data found.');
                        }
                    } else {
                        console.log('API returned success=false or no data property.');
                    }
                } catch (error) {
                    console.error('Error fetching Billable Target:', error);
                }

                // 3. Fetch the billed time
                const billedTimeData = await FinancialService.getBilledTimeByEmployeeId(employeeId);
                console.log('Billed Time Data:', billedTimeData);

                // Sum up the total billed hours from all records
                const totalBilledHours = billedTimeData.reduce((sum, entry) => {
                    const time = parseTime(entry.time_recorded);
                    return sum + time;
                }, 0);

                console.log('Total Billed Hours for this month:', totalBilledHours);

                // 4. Calculate progress for the month
                if (monthlyTarget > 0) {
                    const calculatedProgress = (totalBilledHours / monthlyTarget) * 100;
                    console.log('Calculated Progress (%):', calculatedProgress);
                    setProgress(calculatedProgress);
                } else {
                    console.log('Monthly Target is zero, cannot calculate progress.');
                    setProgress(0);
                }

            } catch (error) {
                console.error('Error calculating billable progress:', error);
            }
        };

        fetchData();
    }, [accounts]);

    return (
        <div className="BT-container">
            <div
                className="BT-bar"
                style={{ width: `${progress.toFixed(2)}%` }}
            />
            <span className="BT-label">
                Billable Target 
            </span>
        </div>
    );
};

export default BilliableTarget;
