import "./GeneralFixedFees.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import FinancialService from "../../AppSettings/services/FinancialService";
import GeneralAddFixedFees from "./Components/GeneralAddFixedFees";

const PAGELIMIT = 12;

const GeneralFixedFees = () => {
  const { data: financialData, isLoading, error } = useQuery({
    queryKey: ["allAssignedFinancialData"],
    queryFn: FinancialService.getAllAssignedFinancialData,
  });

  const [currPage, setCurrPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePageDecrement = () => {
    if (currPage > 1) {
      setCurrPage(currPage - 1);
    }
  };

  const handlePageIncrement = () => {
    if (currPage < PAGELIMIT) {
      setCurrPage(currPage + 1);
    }
  };

  const handleAddButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAddSuccess = () => {
    console.log("Financial data added successfully");
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching financial data</div>;
  }

  return (
    <div className="fixedFees-container">
      <div className="fixedFees-header">
        <h1>Assigned Financial Data</h1>
        <button className="fixedFees-add-button" onClick={handleAddButtonClick}>
          +
        </button>
      </div>
      <div className="fee-table-container">
        <table className="fee-table">
          <thead>
            <tr>
              <th>Fee Code</th>
              <th>Narrative</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Matter ID</th>
            </tr>
          </thead>
          <tbody>
            {financialData.map((row, index) => (
              <tr key={index}>
                <td>
                  <button className="fee-code">{row.fee_code}</button>
                </td>
                <td>{row.narrative}</td>
                <td>{row.fees}</td>
                <td>{row.date_added}</td>
                <td>{row.matterid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="fee-footer">
        <div>
          <button className="fee-pagination-button" onClick={handlePageDecrement}>
            <FontAwesomeIcon icon={faAnglesLeft} className="fee-pagination-button" />
          </button>
          <span>{currPage} of {PAGELIMIT}</span>
          <button className="fee-pagination-button" onClick={handlePageIncrement}>
            <FontAwesomeIcon icon={faAnglesRight} className="fee-pagination-button" />
          </button>
        </div>
      </div>

      {isModalOpen && (
        <GeneralAddFixedFees onClose={handleModalClose} onAddSuccess={handleAddSuccess} />
      )}
    </div>
  );
};

export default GeneralFixedFees;
