import React, { useState, useEffect } from 'react';
import RulesService from '../../../AppSettings/services/RulesService'; // Adjust the path based on your project structure
import './RulesList.css';

const RulesList = () => {
    const [rules, setRules] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRules = async () => {
            try {
                const fetchedRules = await RulesService.getDeadlineRules();
                setRules(fetchedRules);
            } catch (err) {
                setError('An error occurred while fetching rules.');
                console.error(err);
            }
        };

        fetchRules();
    }, []);

    return (
        <div className="rules-list-container">
            <h1 className="rules-title">List of Current Rules</h1>
            <div >
                {error && <p className="error-message">{error}</p>}
                {!error && rules.length === 0 && <p>No rules available.</p>}
                {!error && rules.length > 0 && (
                    <ul className="rules-list">
                        {rules.map((rule) => (
                            <li key={rule.rule_id} className="rule-item">
                                <p><strong>Rule ID:</strong> {rule.rule_id}</p>
                                <p><strong>Date Field:</strong> {rule.date_field}</p>
                                <p><strong>Duration:</strong> {rule.duration}</p>
                                <p><strong>Duration Type:</strong> {rule.duration_type}</p>
                                <p><strong>Matter Type:</strong> {rule.matter_type}</p>
                                <p><strong>Country:</strong> {rule.country}</p>
                                <p><strong>Internal/External:</strong> {rule.internal_external}</p>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default RulesList;
