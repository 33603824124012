import React, { useEffect, useState } from 'react';
import './DeadlineSideBar.css';
import DeadlineChecklistService from '../../../../../../../AppSettings/services/DeadlineChecklistService';

const DeadlineSideBar = ({ deadline, onClose, isOpen }) => {
  const [status, setStatus] = useState(false); // State to track the current status

  // Initialize the status when the deadline prop changes
  useEffect(() => {
    if (deadline) {
      setStatus(deadline.status); // Initialize from the deadline prop
    }
  }, [deadline]);

  // Handle status toggle
  const handleStatusToggle = async () => {
    const newStatus = !status; // Flip the current status
    setStatus(newStatus); // Optimistically update the UI

    try {
      // Update the backend with the new status
      await DeadlineChecklistService.editDeadline(deadline.deadline_id, { status: newStatus });
      console.log('Status updated successfully:', newStatus);
    } catch (error) {
      console.error('Error updating status:', error);
      // Revert the status if the update fails
      setStatus((prevStatus) => !newStatus);
    }
  };

  // Render null if no deadline is selected or the sidebar is closed
  if (!deadline || !isOpen) return null;

  return (
    <div className={`Deadline-sidebar ${isOpen ? 'open' : ''}`}>
      <div className="Deadline-header">
        <h2>Deadline Details</h2>
        <button className="Deadline-close-btn" onClick={onClose}>✕</button>
      </div>

      <div className="Deadline-Sidebar-contents">
        <div className="Deadline-details-container">
          <div className="Deadline-title">
            <h3>{deadline.deadline_type}</h3>
          </div>

          <div className="Deadline-meta">
            <p><strong>Due</strong> - <span>{deadline.due_date}</span></p>
            <p><strong>Internal/External</strong> - <span>{deadline.internal_external}</span></p>
          </div>
        </div>

        <div className="Deadline-info">
          <div className="Deadline-info-row">
          <p><strong>Status </strong></p>
            <button
                className={`status-toggle-button ${status ? 'complete' : 'incomplete'}`}
              onClick={handleStatusToggle}
            >
              {status ? 'Completed' : 'Completed'}
            </button>
          </div>
          <p><strong>ID -</strong> {deadline.matterid}</p>
          <p><strong>Description -</strong> {deadline.type}</p>
        </div>
      </div>
    </div>
  );
};

export default DeadlineSideBar;
