import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import TopBar from "./Components/TopBar"; // Import the TopBar
import Select from "react-select";
import DeadlineChecklistService from "../../../AppSettings/services/DeadlineChecklistService";
import MatterService from "../../../AppSettings/services/MatterService";

import "./CreateChecklistsPage.css";

const CreateChecklistPage = () => {
  const { accounts } = useMsal();
  const { oid } = accounts[0].idTokenClaims;

  const [formData, setFormData] = useState({
    description: "",
    matterid: "",
    duedate: "",
    employeeid: "" // Maybe needed?
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [matters, setMatters] = useState([]);
  const [loadingMatters, setLoadingMatters] = useState(true);

  useEffect(() => {
    const fetchMatters = async () => {
      try {
        const data = await MatterService.getMatters();
        const options = data.map((matter) => ({
          value: matter.matterid,
          label: `${matter.matterid}: ${matter.mattername}`,
        }));
        setMatters(options);
      } catch (error) {
        console.error("Error fetching matters:", error);
        setError("Failed to load matters.");
      } finally {
        setLoadingMatters(false);
      }
    };

    fetchMatters();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMatterChange = (selectedOption) => {
    setFormData({
      ...formData,
      matterid: selectedOption ? selectedOption.value : "",
    });
  };

  const handleChecklistCreation = async () => {
    setLoading(true);
    setError("");

    try {
      console.log(formData);
      const response = await DeadlineChecklistService.addChecklistItem(
        formData
      );
      if (response.error) throw new Error(response.error);
      alert("Checklist item added successfully!");
      setFormData({
        description: "",
        matterid: "",
        duedate: "",
        employeeid: "" // maybe needed?
      });
    } catch (error) {
      console.error("Error adding checklist item:", error);
      setError("Failed to add the checklist item. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="Tasks-Page">
      {/* Pass the handleChecklistCreation function to the TopBar */}
      <TopBar
        formData={formData}
        handleChecklistCreation={handleChecklistCreation}
      />
      <div
        className="Tasks-entire-form-container"
        style={{ justifyContent: "center" }}
      >
        <div className="Tasks-form-container">
          <div className="Tasks-form-group">
            <label className="Tasks-form-group-label">Matter ID</label>
            <Select
              options={matters}
              value={matters.find((matter) => matter.value === formData.matterid) || null}
              onChange={handleMatterChange}
              isLoading={loadingMatters}
              placeholder="Select or search a matter..."
              isClearable
            />
          </div>

          <div className="Tasks-form-group">
            <label className="Tasks-form-group-label">Description</label>
            <textarea
              name="description"
              placeholder="Enter checklist description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>

          <div style={{ paddingBottom: "1em" }}>
            <div className="Tasks-item2">
              <label className="Tasks-form-group-label">Due Date</label>
              <div className="Tasks-input-icon">
                <input
                  type="date"
                  name="duedate"
                  value={formData.duedate}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          {error && <div className="error-message">{error}</div>}
          {loading && (
            <div className="loading-message">Adding checklist item...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateChecklistPage;
